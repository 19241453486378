import React, { memo } from 'react';
import PropTypes from 'prop-types';
import NodeWrapper from '../../wrapper/NodeWrapper';

const Tank = memo((props) => {
  const { id, data } = props;

  console.log(`${id} node rendered`);

  return (
    <NodeWrapper {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlSpace='preserve'
        width={data?.common?.size?.width ?? 80}
        height={data?.common?.size?.height ?? 80}
        viewBox='0 0 87.076 112.5'
        {...props}
      >
        <linearGradient
          id='a'
          x1={0}
          x2={87.043}
          y1={42.333}
          y2={42.333}
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0}
            style={{
              stopColor: '#003700',
            }}
          />
          <stop
            offset={0.5}
            style={{
              stopColor: '#d6ebd6',
            }}
          />
          <stop
            offset={0.57}
            style={{
              stopColor: '#b1d8b1',
            }}
          />
          <stop
            offset={0.71}
            style={{
              stopColor: '#4fa74f',
            }}
          />
          <stop
            offset={0.92}
            style={{
              stopColor: '#005900',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#003700',
            }}
          />
        </linearGradient>
        <path
          fill='url(#a)'
          d='m0 78.587 5.74 3.903h75.925l5.378-3.914V6.09l-5.378-3.914H5.74L0 6.287l.365 72.29'
        />
        <linearGradient
          id='b'
          x1={6.444}
          x2={81.231}
          y1={1.384}
          y2={1.384}
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0.06}
            style={{
              stopColor: 'green',
            }}
          />
          <stop
            offset={0.13}
            style={{
              stopColor: '#198c19',
            }}
          />
          <stop
            offset={0.28}
            style={{
              stopColor: '#5faf5f',
            }}
          />
          <stop
            offset={0.48}
            style={{
              stopColor: '#cfe7cf',
            }}
          />
          <stop
            offset={0.48}
            style={{
              stopColor: '#d4ead4',
            }}
          />
          <stop
            offset={0.57}
            style={{
              stopColor: '#bfdfbf',
            }}
          />
          <stop
            offset={0.72}
            style={{
              stopColor: '#85c285',
            }}
          />
          <stop
            offset={0.92}
            style={{
              stopColor: '#279327',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: 'green',
            }}
          />
        </linearGradient>
        <path
          fill='url(#b)'
          d='M6.444 1.826 44.941 0l36.29 1.982c-25.382 1.364-63.716.713-74.787-.156z'
        />
        <linearGradient
          id='c'
          x1={43.314}
          x2={85.379}
          y1={9.64}
          y2={-0.849}
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0.29}
            style={{
              stopColor: '#ecf5ec',
            }}
          />
          <stop
            offset={0.38}
            style={{
              stopColor: '#d6ebd6',
            }}
          />
          <stop
            offset={0.52}
            style={{
              stopColor: '#9dce9d',
            }}
          />
          <stop
            offset={0.71}
            style={{
              stopColor: '#3f9f3f',
            }}
          />
          <stop
            offset={0.94}
            style={{
              stopColor: '#005e00',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#004c00',
            }}
          />
        </linearGradient>
        <path
          fill='url(#c)'
          d='M81.224 1.951c-10.562.372-34.408.441-38.328.564-.169 1.736-.169 4.771-.169 4.771 7.153.124 41.003-.55 44.35-1.329-2.617-1.897-5.853-4.006-5.853-4.006z'
        />
        <linearGradient
          id='d'
          x1={42.636}
          x2={84.564}
          y1={9.764}
          y2={-0.69}
          gradientTransform='matrix(-1 0 0 1 86.327 0)'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0.29}
            style={{
              stopColor: '#ecf5ec',
            }}
          />
          <stop
            offset={0.38}
            style={{
              stopColor: '#d6ebd6',
            }}
          />
          <stop
            offset={0.52}
            style={{
              stopColor: '#9dce9d',
            }}
          />
          <stop
            offset={0.71}
            style={{
              stopColor: '#3f9f3f',
            }}
          />
          <stop
            offset={0.94}
            style={{
              stopColor: '#005e00',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#004c00',
            }}
          />
        </linearGradient>
        <path
          fill='url(#d)'
          d='M5.948 1.838c10.56.373 34.406.441 38.325.565.17 1.736.035 4.879.035 4.879-7.151.125-40.96-.125-44.308-.905C2.618 4.48 5.948 1.838 5.948 1.838z'
        />
        <linearGradient
          id='e'
          x1={43.538}
          x2={43.538}
          y1={78.412}
          y2={82.875}
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0}
            style={{
              stopColor: '#8fc78f',
            }}
          />
          <stop
            offset={0.29}
            style={{
              stopColor: '#61b061',
            }}
          />
          <stop
            offset={0.91}
            style={{
              stopColor: '#007400',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#006b00',
            }}
          />
        </linearGradient>
        <path fill='url(#e)' d='M.001 78.412v.338l5.625 4.125h76.05l5.399-4.125v-.338z' />
        <path
          fill='none'
          d='m87.075 76.725-.225.45-3.15.9-4.05.675-5.175.45-3.15.225-3.375.225h-3.375l-3.824.225H56.7l-4.05.225H35.325l-4.274-.225h-3.825l-3.825-.225h-3.6l-3.15-.226-3.15-.224-5.175-.45-4.051-.675-3.149-.9-.226-.45'
        />
        <linearGradient
          id='f'
          x1={72.18}
          x2={82.754}
          y1={95.541}
          y2={95.541}
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0}
            style={{
              stopColor: '#003700',
            }}
          />
          <stop
            offset={0.45}
            style={{
              stopColor: '#d6ebd6',
            }}
          />
          <stop
            offset={0.55}
            style={{
              stopColor: '#b1d8b1',
            }}
          />
          <stop
            offset={0.76}
            style={{
              stopColor: '#4fa74f',
            }}
          />
          <stop
            offset={0.77}
            style={{
              stopColor: '#4ba54b',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#003700',
            }}
          />
        </linearGradient>
        <path
          fill='url(#f)'
          d='M82.733 81.609c0-.792-2.326-1.477-5.265-1.477s-5.215.709-5.215 1.501l-.073 29.317h10.574l-.021-29.341z'
        />
        <linearGradient
          id='g'
          x1={70.38}
          x2={84.555}
          y1={111.607}
          y2={111.607}
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0}
            style={{
              stopColor: '#003700',
            }}
          />
          <stop
            offset={0.45}
            style={{
              stopColor: '#d6ebd6',
            }}
          />
          <stop
            offset={0.55}
            style={{
              stopColor: '#b1d8b1',
            }}
          />
          <stop
            offset={0.76}
            style={{
              stopColor: '#4fa74f',
            }}
          />
          <stop
            offset={0.77}
            style={{
              stopColor: '#4ba54b',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#003700',
            }}
          />
        </linearGradient>
        <path fill='url(#g)' d='M70.38 110.715h14.175v1.785H70.38z' />
        <linearGradient
          id='h'
          x1={4.501}
          x2={15.075}
          y1={95.541}
          y2={95.541}
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0}
            style={{
              stopColor: '#003700',
            }}
          />
          <stop
            offset={0.45}
            style={{
              stopColor: '#d6ebd6',
            }}
          />
          <stop
            offset={0.55}
            style={{
              stopColor: '#b1d8b1',
            }}
          />
          <stop
            offset={0.76}
            style={{
              stopColor: '#4fa74f',
            }}
          />
          <stop
            offset={0.77}
            style={{
              stopColor: '#4ba54b',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#003700',
            }}
          />
        </linearGradient>
        <path
          fill='url(#h)'
          d='M15.054 81.609c0-.792-2.326-1.477-5.265-1.477s-5.215.709-5.215 1.501l-.073 29.317h10.574l-.021-29.341z'
        />
        <linearGradient
          id='i'
          x1={2.701}
          x2={16.876}
          y1={111.607}
          y2={111.607}
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0}
            style={{
              stopColor: '#003700',
            }}
          />
          <stop
            offset={0.45}
            style={{
              stopColor: '#d6ebd6',
            }}
          />
          <stop
            offset={0.55}
            style={{
              stopColor: '#b1d8b1',
            }}
          />
          <stop
            offset={0.76}
            style={{
              stopColor: '#4fa74f',
            }}
          />
          <stop
            offset={0.77}
            style={{
              stopColor: '#4ba54b',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#003700',
            }}
          />
        </linearGradient>
        <path fill='url(#i)' d='M2.701 110.715h14.175v1.785H2.701z' />
        <linearGradient
          id='j'
          x1={57.571}
          x2={66.996}
          y1={93.224}
          y2={93.224}
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0.17}
            style={{
              stopColor: '#004c00',
            }}
          />
          <stop
            offset={0.23}
            style={{
              stopColor: '#005900',
            }}
          />
          <stop
            offset={0.34}
            style={{
              stopColor: '#007c00',
            }}
          />
          <stop
            offset={0.48}
            style={{
              stopColor: '#63b163',
            }}
          />
          <stop
            offset={0.57}
            style={{
              stopColor: '#4fa74f',
            }}
          />
          <stop
            offset={0.73}
            style={{
              stopColor: '#158a15',
            }}
          />
          <stop
            offset={0.94}
            style={{
              stopColor: '#005b00',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#004c00',
            }}
          />
        </linearGradient>
        <path
          fill='url(#j)'
          d='M66.977 81.805c0-.705-2.074-1.316-4.692-1.316-2.62 0-4.648.633-4.648 1.34l-.065 24.131h9.426l-.021-24.155z'
        />
        <linearGradient
          id='k'
          x1={55.967}
          x2={68.6}
          y1={106.543}
          y2={106.543}
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0.17}
            style={{
              stopColor: '#004c00',
            }}
          />
          <stop
            offset={0.23}
            style={{
              stopColor: '#005900',
            }}
          />
          <stop
            offset={0.34}
            style={{
              stopColor: '#007c00',
            }}
          />
          <stop
            offset={0.48}
            style={{
              stopColor: '#63b163',
            }}
          />
          <stop
            offset={0.57}
            style={{
              stopColor: '#4fa74f',
            }}
          />
          <stop
            offset={0.73}
            style={{
              stopColor: '#158a15',
            }}
          />
          <stop
            offset={0.94}
            style={{
              stopColor: '#005b00',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#004c00',
            }}
          />
        </linearGradient>
        <path fill='url(#k)' d='M55.967 105.748H68.6v1.591H55.967z' />
        <linearGradient
          id='l'
          x1={19.884}
          x2={29.309}
          y1={93.211}
          y2={93.211}
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0.17}
            style={{
              stopColor: '#004c00',
            }}
          />
          <stop
            offset={0.23}
            style={{
              stopColor: '#005900',
            }}
          />
          <stop
            offset={0.34}
            style={{
              stopColor: '#007c00',
            }}
          />
          <stop
            offset={0.48}
            style={{
              stopColor: '#63b163',
            }}
          />
          <stop
            offset={0.57}
            style={{
              stopColor: '#4fa74f',
            }}
          />
          <stop
            offset={0.73}
            style={{
              stopColor: '#158a15',
            }}
          />
          <stop
            offset={0.94}
            style={{
              stopColor: '#005b00',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#004c00',
            }}
          />
        </linearGradient>
        <path
          fill='url(#l)'
          d='M29.29 81.792c0-.705-2.073-1.316-4.692-1.316-2.62 0-4.648.633-4.648 1.34l-.065 24.131h9.425l-.02-24.155z'
        />
        <linearGradient
          id='m'
          x1={18.279}
          x2={30.914}
          y1={106.531}
          y2={106.531}
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0.17}
            style={{
              stopColor: '#004c00',
            }}
          />
          <stop
            offset={0.23}
            style={{
              stopColor: '#005900',
            }}
          />
          <stop
            offset={0.34}
            style={{
              stopColor: '#007c00',
            }}
          />
          <stop
            offset={0.48}
            style={{
              stopColor: '#63b163',
            }}
          />
          <stop
            offset={0.57}
            style={{
              stopColor: '#4fa74f',
            }}
          />
          <stop
            offset={0.73}
            style={{
              stopColor: '#158a15',
            }}
          />
          <stop
            offset={0.94}
            style={{
              stopColor: '#005b00',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#004c00',
            }}
          />
        </linearGradient>
        <path fill='url(#m)' d='M18.28 105.735h12.634v1.591H18.28z' />
      </svg>
    </NodeWrapper>
  );
});

export default Tank;

Tank.propTypes = {
  id: PropTypes.any,
  data: PropTypes.object,
};

Tank.displayName = 'Tank';
