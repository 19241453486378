import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import useFlowground from '../../utility/hooks/useFlowground';
import { GeneralSidebar, LayerSidebar, Playground } from './index';
import TopSidebar from './layouts/TopSidebar';

const FlowgroundComponent = () => {
  const {
    topNavbarHeight,
    layerSidebarWidth,
    flowgroundWidth,
    generalSidebarWidth,
    flowgroundHeight,
    isOpenLayerSidebar,
    isOpenGeneralSidebar,
    setFlowgroundWidth,
    setFlowgroundHeight,
  } = useFlowground();

  // on resize window
  useEffect(() => {
    const handleResize = () => {
      setFlowgroundHeight(window.innerHeight - topNavbarHeight);
      setFlowgroundWidth(window.innerWidth - (layerSidebarWidth + generalSidebarWidth));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: topNavbarHeight,
        }}
      >
        <TopSidebar />
      </Box>
      <Box
        sx={{
          width: '100%',
          height: flowgroundHeight,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        {
          isOpenLayerSidebar && (
            <Box
              sx={{
                width: layerSidebarWidth,
                backgroundColor: '#f1f3f4',
                border: '1px solid #dadce0',
                borderRight: 'none',
                padding: '0!important',
                boxSizing: 'content-box'
              }}
            >
              <LayerSidebar />
            </Box>
          )
        }
        <Box
          sx={{
            width: flowgroundWidth,
            border: '1px solid #dadce0',
            padding: '0!important'
          }}
        >
          <Playground />
        </Box>
        {
          isOpenGeneralSidebar && (
            <Box
              sx={{
                width: generalSidebarWidth,
                backgroundColor: '#f1f3f4',
                border: '1px solid #dadce0',
                borderLeft: 'none',
                padding: '0!important',
                boxSizing: 'content-box'
              }}
            >
              <GeneralSidebar />
            </Box>
          )
        }
      </Box>
    </>
  )
}

export default FlowgroundComponent;