import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControlLabel, FormGroup,
  Grid,
  MenuItem, Switch,
  TextField
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import useFlowground from '../../../utility/hooks/useFlowground';

const FlowgroundSettingModal = (props) => {
  const { isOpenSettings, setIsOpenSettings } = props;
  const { flowgroundSettings, setFlowgroundSettings } = useFlowground();
  const formRef = useRef(null);

  const handleClose = () => {
    setIsOpenSettings(false);
  }

  const handleSubmit = () => {
    formRef.current.submitForm();
  }

  const onSubmitForm = (values) => {
    console.log({
      values
    });
    setFlowgroundSettings(values);
  }

  return (
    <>
      <Dialog
        open={isOpenSettings}
        onClose={handleClose}
      >
        <DialogTitle>Flowground Settings</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: 500
            }}
          >
            <Formik
              initialValues={{
                backgroundVariant: flowgroundSettings.backgroundVariant,
                minimap: flowgroundSettings.minimap,
                zoom: flowgroundSettings.zoom,
                fitOnView: flowgroundSettings.fitOnView
              }}
              // validationSchema={Yup.object().shape({
              //   name: Yup.string().required('Name is required'),
              //   type: Yup.string().required('Node list is required'),
              // })}
              onSubmit={onSubmitForm}
              innerRef={formRef}
            >
              {({ values, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={0.5}
                  >
                    <Grid
                      item
                      md={12}
                    >
                      <TextField
                        margin="dense"
                        select
                        label="Background Variants"
                        variant="outlined"
                        name="backgroundVariant"
                        size="small"
                        fullWidth
                        onChange={handleChange}
                        value={values.backgroundVariant || ''}
                        error={Boolean(touched.backgroundVariant && errors.backgroundVariant)}
                        helperText={touched.backgroundVariant && errors.backgroundVariant}
                      >
                        <MenuItem
                          value='dots'
                        >
                          Dots
                        </MenuItem>
                        <MenuItem
                          value='lines'
                        >
                          Lines
                        </MenuItem>
                        <MenuItem
                          value='cross'
                        >
                          Cross
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      md={12}
                    >
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="minimap"
                          control={
                            <Switch
                              color="primary"
                              value="minimap"
                              checked={values.minimap || false}
                              onChange={(event) => {
                                setFieldValue('minimap', event.target.checked);
                              }}
                            />
                          }
                          label="Minimap"
                          labelPlacement="start"
                        />
                      </FormGroup>
                    </Grid>

                    <Grid
                      item
                      md={12}
                    >
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              value="zoom"
                              checked={values.zoom || false}
                              onChange={(event) => {
                                setFieldValue('zoom', event.target.checked);
                              }}
                            />
                          }
                          label="Zoom"
                          labelPlacement="start"
                        />
                      </FormGroup>
                    </Grid>

                    <Grid
                      item
                      md={12}
                    >
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              name="fitOnView"
                              checked={values.fitOnView || false}
                              onChange={(event) => {
                                setFieldValue('fitOnView', event.target.checked);
                              }}
                            />
                          }
                          label="Fit On View"
                          labelPlacement="start"
                        />
                      </FormGroup>
                    </Grid>

                  </Grid>
                </form>
              )}
            </Formik>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type='button'
            variant='contained'
            onClick={handleSubmit}
          >
            Change
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FlowgroundSettingModal;

FlowgroundSettingModal.propTypes = {
  isOpenSettings: PropTypes.bool,
  setIsOpenSettings: PropTypes.func,
}