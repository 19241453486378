import React, { createContext, useCallback, useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { applyNodeChanges, useReactFlow } from 'reactflow';
import availableNodeTypes from "../config/nodeTypes";
import nodeDefaultValues from "../config/nodeDefaultValues";
import flowgroundSectionSizes from '../config/flowgroundSectionSizes';
import initialFlowground from "../helpers/flowground/initial";
import nodeSave from "../helpers/flowground/nodeSave";
import applySortByValue from '../helpers/flowground/applySortByValue';

const FlowgroundContext = createContext("");

const FlowgroundProvider = (props) => {
  const { children } = props;
  const { zoomIn, zoomOut } = useReactFlow();

  // VALUES
  // refs
  const firstHitNodesSelectionChanges = useRef(false);
  const layerListRef = useRef(null);
  const didMountFrameProperties = useRef(false);
  // refs

  // sidebar size
  const [ topNavbarHeight, setTopNavbarHeight ] = useState(flowgroundSectionSizes.navbarHeight);
  const [ layerSidebarWidth, setLayerSidebarWidth ] = useState(flowgroundSectionSizes.sidebarWidth);
  const [ generalSidebarWidth, setGeneralSidebarWidth ] = useState(flowgroundSectionSizes.sidebarWidth);
  // sidebar size

  // playground sizes
  // width
  const [ flowgroundWidth, setFlowgroundWidth ] = useState(window.innerWidth - (layerSidebarWidth + generalSidebarWidth));
  // height
  const [ flowgroundHeight, setFlowgroundHeight ] = useState(window.innerHeight - topNavbarHeight);
  // playground sizes

  // sidebar
  const [ isOpenLayerSidebar, setIsOpenLayerSidebar ] = useState(true);
  const [ isOpenGeneralSidebar, setIsOpenGeneralSidebar ] = useState(true);
  // sidebar

  // node types
  const [ nodeTypes, setNodeTypes ] = useState({});
  // node types

  // nodes
  const [ nodes, setNodes ] = useState([]);
  // nodes

  // nodes layer
  const [ nodesLayer, setNodesLayer ] = useState([]);
  // nodes layer

  // nodes setting
  const [ nodesSetting, setNodesSetting ] = useState([]);
  // nodes setting

  // frame
  const [ frameProperties, setFrameProperties ] = useState({});
  // frame

  // zIndex
  const [ nodeCurrentZIndex, setNodeCurrentZIndex ] = useState(0);
  // zIndex

  // node save
  const [ nodeIsSaved, setNodeIsSaved ] = useState(false);
  // node save

  // selection event
  const [ selectionEvent, setSelectionEvent ] = useState(false);
  // selection event

  // selected nodes
  const [ selectedNodes, setSelectedNodes ] = useState([]);
  // selected nodes

  // frame update modal
  const [ toggleFrameUpdateModal, setToggleFrameUpdateModal ] = useState(false);
  const [ toggleNodeAddModal, setToggleNodeAddModal ] = useState(false);
  // frame update modal

  // general sidebar
  const [ generalSidebarSelectedTabIndex, setGeneralSidebarSelectedTabIndex ] = useState(0);
  // general sidebar

  // selection changes listener
  const [ nodesSelectionChanges, setNodesSelectionChanges ] = useState(undefined);
  // selection changes listener

  // create changes listener
  const [ nodesCreateChanges, setNodesCreateChanges ] = useState(undefined);
  // create changes listener

  // delete changes listener
  const [ nodesDeleteChanges, setNodesDeleteChanges ] = useState(undefined);
  // delete changes listener

  // layer changes listener
  const [ nodesLayerChanges, setNodesLayerChanges ] = useState(undefined);
  // layer changes listener

  // setting changes listener
  const [ nodesSettingChanges, setNodesSettingChanges ] = useState(undefined);
  // setting changes listener

  // flowground settings
  const [ flowgroundSettings, setFlowgroundSettings ] = useState({});
  // flowground settings

  // context menu
  const [ isOpenContextMenu, setIsOpenContextMenu ] = useState(false);
  const [ contextMenuPosition, setContextMenuPosition ] = useState({ x: 0, y: 0 });
  const [ contextMenuNode, setContextMenuNode ] = useState({});
  // context menu
  // VALUES

  // FUNCTIONS
  // node add
  const handleCreateNode = (data) => {

    // const createChangesData = [ data ];
    setNodesCreateChanges([ data ]);

  }
  // node add

  // node delete
  const handleDeleteNode = (data) => {

    setNodesDeleteChanges(data);

  }
  // node delete

  // node save
  const handleNodeSave = () => {
    nodeSave(nodes);
    setNodeIsSaved(false);
  }
  // node save

  // node lock toggle
  const toggleNodeLock = useCallback((node) => {

    const nodesLockChanges = {
      type: 'draggable',
      data: [
        {
          id: node?.id,
          data: !node?.draggable
        }
      ]
    }

    setNodesLayerChanges(nodesLockChanges);

  }, []);
  // node lock toggle

  // node visible toggle
  const toggleNodeVisible = useCallback((node) => {

    const nodesLockChanges = {
      type: 'hidden',
      data: [
        {
          id: node?.id,
          data: !node?.hidden
        }
      ]
    }

    setNodesLayerChanges(nodesLockChanges);

  }, []);
  // node visible toggle

  // node handle select
  const handleNodeSelect = useCallback((event, node) => {
    setNodes((nodes) => {
      return nodes.map((_node) => {
        if (_node.id === node.id) {
          return {
            ..._node,
            selected: !_node.selected
          }
        } else {
          if (_node.selected) {
            return {
              ..._node,
              selected: false
            }
          } else {
            return _node;
          }
        }
      });
    });
  }, []);
  // node handle select

  // zoom in
  const flowgroundZoomIn = () => {
    zoomIn({ duration: 100 });
  }
  // zoom in

  // zoom out
  const flowgroundZoomOut = () => {
    zoomOut({ duration: 100 });
  }
  // zoom out

  // scroll layer list
  const scrollLayerList = (index) => {
    const layerListHeight = layerListRef.current.clientHeight - 40;
    const firstSelectedNodeHeight = index * 36;
    const layerListCountOnScreen = (layerListHeight / 36) / 2;

    layerListRef.current.scrollTop = -1 * (layerListHeight - firstSelectedNodeHeight) + (36 * layerListCountOnScreen);
  };
  // scroll layer list

  // node handle resize end
  const handleOnResizeEnd = useCallback((event, nodeId, params) => {

    setNodes((nodes) => {
      return nodes.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              common: {
                ...node.data.common,
                size: {
                  ...node.data.size,
                  width: params.width,
                  height: params.height,
                }
              }
            }
          }
        }

        return node;
      });
    });

    setNodesSetting((nodesSetting) => {
      return nodesSetting.map((nodeSetting) => {
        if (nodeSetting.id === nodeId) {
          return {
            ...nodeSetting,
            size: {
              width: params.width,
              height: params.height
            }
          }
        }

        return nodeSetting
      })
    })
  }, []);
  // node handle resize

  // node handle drag stop
  const handleNodeDragStop = useCallback((event, node) => {
    if (node.dragging) {

      setNodesSetting((nodesSetting) => {
        return nodesSetting.map((nodeSetting) => {
          if (nodeSetting.id === node.id) {
            return {
              ...nodeSetting,
              position: {
                x: node.position.x,
                y: node.position.y
              }
            }
          }
        });
      })

      setNodeIsSaved(true);
    }
  }, []);
  // node handle drag stop

  // node handle setting
  const nodeHandleSetting = useCallback((data) => {
    setNodesSettingChanges(data);
  });
  // node handle setting

  // handle open context menu
  const handleOpenContextMenu = (event, node) => {
    setContextMenuPosition({
      x: event.clientX,
      y: event.clientY
    });
    setIsOpenContextMenu(true);
    setContextMenuNode(node);
  }
  // handle open context menu

  // handle close context menu
  const handleCloseContextMenu = () => {
    setIsOpenContextMenu(false);
  }
  // handle close context menu

  // node bring to front
  
  // node bring to front
  // FUNCTIONS

  // LISTENER
  // initial
  useEffect(() => {
    // set nodes
    const storageFrameProperties = JSON.parse(localStorage.getItem('frameProperties'));

    const defaultFrameValue = nodeDefaultValues.filter((_value) => {
      if (_value.type === 'frame') {
        return _value.data;
      }
    })[0]?.data;

    const initialFrameProperties = {
      width: storageFrameProperties?.width ?? defaultFrameValue?.width,
      height: storageFrameProperties?.height ?? defaultFrameValue?.height,
      backgroundColor: storageFrameProperties?.backgroundColor ?? defaultFrameValue?.backgroundColor,
      opacity: storageFrameProperties?.opacity ?? defaultFrameValue?.opacity,
      backgroundImage: null
    };

    initialFlowground({
      get: {
        frameProperties: initialFrameProperties,
        nodeCurrentZIndex,
      },
      set: {
        setNodes,
        setFrameProperties,
        setNodeCurrentZIndex,
        setNodesSetting,
        setNodesLayer,
        setFlowgroundSettings,
      }
    });

    // set node types
    setNodeTypes(availableNodeTypes);
    // set layer nodes

    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'Script error.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        )
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        )
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });

  }, []);
  // initial

  // frame properties is changed
  useEffect(() => {
    if (didMountFrameProperties.current === true) {

      const newNodes = nodes.map((node) => {
        if (node.id === 'workspace') {
          return {
            ...node,
            style: {
              width: frameProperties?.width,
              height: frameProperties?.height,
              visibility: 'hidden',
            }
          }
        } else if (node.id === 'frame') {
          return {
            ...node,
            data: {
              width: frameProperties?.width ?? 1920,
              height: frameProperties?.height ?? 1080,
              backgroundColor: frameProperties?.backgroundColor ?? '#f1f3f4',
              opacity: frameProperties?.opacity ?? 0.3,
              backgroundImage: frameProperties?.backgroundImage,
            }
          }
        } else {
          return { ...node };
        }
      });
      setNodes(newNodes);

      // save local storage
      localStorage.setItem('frameProperties', JSON.stringify(frameProperties));
    } else {
      didMountFrameProperties.current = true;
    }
  }, [ frameProperties ]);
  // frame properties is changed

  // nodes selection changes
  useEffect(() => {
    if (nodesSelectionChanges !== undefined) {

      setNodesLayer((nodesLayer) => {
        return nodesLayer.map((nodeLayer) => {
          const findNode = nodesSelectionChanges.find(o => o.id === nodeLayer.id);

          // find item
          if (findNode) {
            return {
              ...nodeLayer,
              selected: findNode.selected
            }
          } else {
            if (nodeLayer.selected) {
              return {
                ...nodeLayer,
                selected: false
              }
            }
            return nodeLayer;
          }
        });
      });

      const appendNodesSetting = nodesSelectionChanges.map((nodeSelectionChanges) => {
        return {
          id: nodeSelectionChanges?.id,
          type: nodeSelectionChanges?.type,
          name: nodeSelectionChanges?.data?.name,
          selected: nodeSelectionChanges?.selected,
          hidden: nodeSelectionChanges?.hidden,
          draggable: nodeSelectionChanges?.draggable,
          zIndex: nodeSelectionChanges?.zIndex,
          position: nodeSelectionChanges?.position,
          size: nodeSelectionChanges?.data?.common?.size,
          rotate: nodeSelectionChanges?.data?.common?.rotate,
          opacity: nodeSelectionChanges?.data?.common?.opacity,
          border: nodeSelectionChanges?.data?.common?.border,
          backgroundColor: nodeSelectionChanges?.data?.common?.backgroundColor,
          backgroundImage: nodeSelectionChanges?.data?.common?.backgroundImage
        }
      });

      setNodesSetting(appendNodesSetting);
    }
  }, [ nodesSelectionChanges ]);
  // nodes selection changes

  // nodes create changes listener
  useEffect(() => {

    if (nodesCreateChanges !== undefined) {

      const appendNodes = [];
      const appendNodesLayer = [];

      // appendNodes & appendNodesLayer create
      nodesCreateChanges.forEach((nodeCreateChanges) => {
        const nodeZIndex = nodeCurrentZIndex + 1;
        setNodeCurrentZIndex((nodeZIndex) => nodeZIndex + 1);

        appendNodes.push({
          id: nodeCreateChanges.name,
          type: nodeCreateChanges.type,
          parentNode: 'workspace',
          extent: 'parent',
          data: {
            name: nodeCreateChanges.name,
            properties: {
              draggable: true,
              connectable: false,
              hidden: false,
            },
            common: {
              size: {
                width: nodeCreateChanges.width,
                height: nodeCreateChanges.height
              },
              rotate: 0,
              opacity: 1,
              border: {
                status: false,
                width: null,
                style: null,
                color: null
              },
              backgroundColor: {
                status: false,
                color: null
              },
              backgroundImage: {
                status: false,
                filePath: null,
                repeat: null
              }
            },
            advanced: {}
          },
          position: {
            x: nodeCreateChanges.xPos,
            y: nodeCreateChanges.yPos
          },
          zIndex: nodeZIndex,
          connectable: false,
          hidden: false,
          draggable: true,
          selected: false
        });

        appendNodesLayer.push({
          id: nodeCreateChanges.name ?? null,
          type: nodeCreateChanges.type ?? null,
          name: nodeCreateChanges.name ?? '',
          zIndex: nodeZIndex,
          selected: false,
          hidden: false,
          draggable: true,
        });
      });

      // nodes append new node
      setNodes((nodes) => {
        const newNodes = nodes.concat(appendNodes);
        newNodes.sort(applySortByValue("zIndex"));
        return newNodes;
      });

      // nodes layer append new node
      setNodesLayer((nodesLayer) => {
        const newNodesLayer = nodesLayer.concat(appendNodesLayer);
        newNodesLayer.sort(applySortByValue("zIndex"));
        return newNodesLayer;
      });

      // set nodeIsSave
      setNodeIsSaved(true);
    }

  }, [ nodesCreateChanges ]);
  // nodes create changes listener

  // nodes delete changes listener
  useEffect(() => {

    if (nodesDeleteChanges !== undefined) {

      const nodesDeletesIds = nodesDeleteChanges.map((nodeDeleteChange) => {
        return nodeDeleteChange.id;
      });

      setNodes((nodes) => {
        return nodes.filter(obj => !nodesDeletesIds.includes(obj.id));
      });

      setNodesLayer((nodesLayer) => {
        return nodesLayer.filter(obj => !nodesDeletesIds.includes(obj.id));
      });

      setNodeIsSaved(true);
    }

  }, [ nodesDeleteChanges ]);
  // nodes delete changes listener

  // nodes layer changes listener
  useEffect(() => {

    if (nodesLayerChanges !== undefined) {

      if (nodesLayerChanges.type === 'draggable') {

        setNodes((nodes) => {
          return nodes.map((node) => {
            const findNode = nodesLayerChanges.data.find(o => o.id === node.id);

            // find item
            if (findNode) {
              return {
                ...node,
                draggable: findNode.data,
                data: {
                  ...node.data,
                  properties: {
                    ...node.data.properties,
                    draggable: findNode.data
                  }
                }
              }
            }

            return node;
          });
        });

        //
        setNodesLayer((nodesLayer) => {
          return nodesLayer.map((nodeLayer) => {
            const findNode = nodesLayerChanges.data.find(o => o.id === nodeLayer.id);

            if (findNode) {
              return {
                ...nodeLayer,
                draggable: findNode.data,
              }
            }

            return nodeLayer;
          })
        });

      }

      if (nodesLayerChanges.type === 'hidden') {

        setNodes((nodes) => {
          return nodes.map((node) => {
            const findNode = nodesLayerChanges.data.find(o => o.id === node.id);

            if (findNode) {
              return {
                ...node,
                hidden: findNode.data,
                data: {
                  ...node.data,
                  properties: {
                    ...node.data.properties,
                    hidden: findNode.data
                  }
                }
              }
            }

            return node;
          });
        });

        setNodesLayer((nodesLayer) => {
          return nodesLayer.map((nodeLayer) => {
            const findNode = nodesLayerChanges.data.find(o => o.id === nodeLayer.id);

            if (findNode) {
              return {
                ...nodeLayer,
                hidden: findNode.data
              }
            }

            return nodeLayer;
          })
        });

      }
    }

  }, [ nodesLayerChanges ]);
  // nodes layer changes listener

  // nodes setting changes listener
  useEffect(() => {

    if (nodesSettingChanges !== undefined) {

      console.log({
        nodesSettingChanges
      });

      setNodes((nodes) => {
        return nodes.map((node) => {

          if (node.id === nodesSettingChanges.id) {
            return {
              ...node,
              data: {
                ...node.data,
                common: {
                  ...node.data.common,
                  size: {
                    width: parseInt(nodesSettingChanges.width, 10),
                    height: parseInt(nodesSettingChanges.height, 10)
                  },
                  rotate: parseInt(nodesSettingChanges.rotate, 10),
                  opacity: parseFloat(nodesSettingChanges.opacity)
                }
              },
              position: {
                x: parseInt(nodesSettingChanges.x, 10),
                y: parseInt(nodesSettingChanges.y, 10),
              },
              style: {
                width: parseInt(nodesSettingChanges.width, 10),
                height: parseInt(nodesSettingChanges.height, 10)
              }
            }
          }

          return node;
        });
      });
    }

  }, [ nodesSettingChanges ]);
  // nodes setting changes listener

  // node change on playground
  const onNodesChange = useCallback((changes) => {
    setNodes((nodes) => {
      return applyNodeChanges(changes, nodes);
    });

  }, []);
  // node change on playground

  // node selection change on playground
  const onSelectionChange = useCallback((changes) => {
    if (!firstHitNodesSelectionChanges.current) {
      firstHitNodesSelectionChanges.current = true;
      return;
    }

    setNodesSelectionChanges(changes.nodes);
  }, []);
  // node selection change on playground

  // LISTENER

  const data = {
    // sidebar & navbar
    topNavbarHeight,
    setTopNavbarHeight,
    layerSidebarWidth,
    setLayerSidebarWidth,
    generalSidebarWidth,
    setGeneralSidebarWidth,

    // flowground width&height
    flowgroundWidth,
    setFlowgroundWidth,
    flowgroundHeight,
    setFlowgroundHeight,

    // layer sidebar
    isOpenLayerSidebar,
    setIsOpenLayerSidebar,

    // general sidebar
    isOpenGeneralSidebar,
    setIsOpenGeneralSidebar,

    // nodes
    nodes,
    setNodes,

    // node types
    nodeTypes,
    setNodeTypes,

    // on node change
    onNodesChange,

    // on node selection change
    onSelectionChange,

    // frame properties
    frameProperties,
    setFrameProperties,

    // toggle frame update modal
    toggleFrameUpdateModal,
    setToggleFrameUpdateModal,

    // node add modal
    toggleNodeAddModal,
    setToggleNodeAddModal,

    // node save
    nodeIsSaved,
    setNodeIsSaved,
    handleNodeSave,

    // node zIndex
    nodeCurrentZIndex,
    setNodeCurrentZIndex,

    // node selection event
    selectionEvent,
    setSelectionEvent,

    // node lock toggle
    toggleNodeLock,

    // node visible toggle
    toggleNodeVisible,

    // node handle select
    handleNodeSelect,

    // selected nodes
    selectedNodes,
    setSelectedNodes,

    // zoom
    flowgroundZoomIn,
    flowgroundZoomOut,

    // layer list ref
    layerListRef,
    scrollLayerList,

    // general sidebar
    generalSidebarSelectedTabIndex,
    setGeneralSidebarSelectedTabIndex,

    // nodes layer
    nodesLayer,
    setNodesLayer,

    // node handle resize end
    handleOnResizeEnd,

    // nodes setting
    nodesSetting,
    setNodesSetting,

    // handle create node
    handleCreateNode,

    // handle delete node
    handleDeleteNode,

    // handle setting node
    nodeHandleSetting,

    // handle node drag stop
    handleNodeDragStop,

    // flowgroundSettings
    flowgroundSettings,
    setFlowgroundSettings,

    // context menu
    handleOpenContextMenu,
    handleCloseContextMenu,
    isOpenContextMenu,
    contextMenuPosition,
    contextMenuNode
  };

  return (
    <FlowgroundContext.Provider
      value={data}
    >
      {children}
    </FlowgroundContext.Provider>
  )
}

export { FlowgroundProvider, FlowgroundContext };

FlowgroundProvider.propTypes = {
  children: PropTypes.any
}