import React, { useRef } from "react";
import { Formik } from "formik";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid, Box, InputAdornment, MenuItem, Slider } from "@mui/material";
import { MuiColorInput } from 'mui-color-input';
import useFlowground from "../../../utility/hooks/useFlowground";

const frameList = [
  {
    label: 'Mobile S (360*780)',
    value: 'mobile-s',
    data: {
      width: 360,
      height: 780
    }
  },
  {
    label: 'Mobile M (390*780)',
    value: 'mobile-m',
    data: {
      width: 390,
      height: 780
    }
  },
  {
    label: 'Mobile L (428*926)',
    value: 'mobile-l',
    data: {
      width: 428,
      height: 926
    }
  },
  {
    label: 'Tablet (768*1024)',
    value: 'tablet',
    data: {
      width: 768,
      height: 1024
    }
  },
  {
    label: 'Laptop-M (1366*768)',
    value: 'laptop-m',
    data: {
      width: 1366,
      height: 768
    }
  },
  {
    label: 'Laptop L (1440*900)',
    value: 'laptop-l',
    data: {
      width: 1440,
      height: 900
    }
  },
  {
    label: 'HD (1920*1080)',
    value: 'hd',
    data: {
      width: 1920,
      height: 1080
    }
  },
  {
    label: '2K (2560*1440)',
    value: '2k',
    data: {
      width: 2560,
      height: 1440
    }
  },
  {
    label: '4K (3840*2160)',
    value: '4k',
    data: {
      width: 3840,
      height: 2160
    }
  }
];

const FramePropertiesUpdateModal = () => {
  const { toggleFrameUpdateModal, setToggleFrameUpdateModal, frameProperties, setFrameProperties } = useFlowground();
  const formRef = useRef(null);
  
  const handleClose = () => {
    setToggleFrameUpdateModal(false);
  }

  const onSubmitForm = (values) => {
    let frameOpacity = {};
    if (values?.opacity != frameProperties.opacity) {
      frameOpacity = {
        opacity: values?.opacity
      }
    }

    setFrameProperties({
      ...values,
      ...frameOpacity,
    });
    handleClose();
  }

  return (
    <>
      <Dialog
        open={toggleFrameUpdateModal}
        onClose={handleClose}
      >
        <DialogTitle>Frame Properties Update</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: 500
            }}
          >
            <Formik
              initialValues={{
                ...frameProperties,
                frame: null,
              }}
              onSubmit={onSubmitForm}
              innerRef={formRef}
            >
              {({ values, handleChange, handleSubmit, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={1}
                  >
                    <Grid
                      item
                      md={12}
                    >
                      <TextField
                        margin="dense"
                        select
                        label="Frame List"
                        variant="outlined"
                        name="frame"
                        size="small"
                        fullWidth
                        onChange={(e) => {
                          const value = e.target.value;
                          const frameSizes = frameList.filter((_frame) => {
                            return _frame.value === value;
                          })[0]?.data;
                          setFieldValue('frame', e.target.value);
                          setFieldValue('width', frameSizes.width);
                          setFieldValue('height', frameSizes.height);
                        }}
                        value={values.frame || ''}
                      >
                        {
                          frameList.map((frame, index) => {
                            return (
                              <MenuItem
                                key={`frame_${index}`}
                                value={frame.value}
                              >
                                {frame.label}
                              </MenuItem>
                            )
                          })
                        }
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={6}
                    >
                      <TextField
                        margin="dense"
                        label="Width"
                        type="number"
                        name="width"
                        value={values?.width || ''}
                        onChange={handleChange}
                        fullWidth
                        autoComplete="off"
                        variant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              px
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={6}
                    >
                      <TextField
                        margin="dense"
                        label="Height"
                        type="number"
                        name="height"
                        value={values?.height || ''}
                        onChange={handleChange}
                        fullWidth
                        autoComplete="off"
                        variant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              px
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={12}
                    >
                      <MuiColorInput
                        fullWidth
                        format="hex"
                        name="backgroundColor"
                        value={values.backgroundColor}
                        isAlphaHidden
                        onChange={(color) => {
                          setFieldValue('backgroundColor', color);
                        }}
                        size="small"
                      />
                    </Grid>
                    <Grid
                      item
                      md={12}
                    >
                      <Slider
                        // value={}
                        onChange={(event, value) => {
                          setFieldValue('opacity', value);
                        }}
                        aria-label="Temperature"
                        value={values?.opacity ?? 0}
                        valueLabelDisplay="auto"
                        step={0.1}
                        marks
                        min={0}
                        max={1}
                        track={false}
                      />
                    </Grid>
                    <Grid
                      item
                      md={12}
                    >
                      <input
                        type="file"
                        name="backgroundImage"
                        onChange={(event) => {
                          // console.log(event.target.files[0]);
                          setFieldValue("backgroundImage", event.target.files[0]);
                        }}
                      />
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            onClick={() => {
              formRef.current.submitForm();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
};

export default FramePropertiesUpdateModal;
