import React, { memo } from "react";
import PropTypes from "prop-types";
import NodeWrapper from "../../wrapper/NodeWrapper";

const HorizontalLine = memo((props) => {
  const { id, data } = props;

  // console.log(`${id} node rendered`);

  return (
      <div
      style={{
        width: 53,
        height: 0,
        border: '1px solid #c4c4c4',
        margin: 3,
        display: 'inline-block'
      }}
      ></div>
  )
});

export default HorizontalLine;

HorizontalLine.propTypes = {
  id: PropTypes.any,
  data: PropTypes.object,
};

HorizontalLine.displayName = 'Horizontal';
