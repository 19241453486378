import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";

const Frame = memo((props) => {
  const { data } = props;

  return (
    <Box
      sx={{
        width: data?.width ?? 1920,
        height: data?.height ?? 1080,
        border: '1px solid #d1d1d1',
        backgroundColor: data?.backgroundColor || '#f1f3f4',
        opacity: data?.opacity ?? 0.3,
        boxShadow: '0px 0px 2px 1px #d1d1d1',
        backgroundImage: data?.backgroundImage ? `url(${URL.createObjectURL(data?.backgroundImage)})` ?? 'none' : 'none',
      }}
    />
  )
})

export default Frame;

Frame.propTypes = {
  data: PropTypes.object
};

Frame.displayName = 'Frame';
