import React, { memo } from 'react';
import PropTypes from 'prop-types';
import NodeWrapper from '../../wrapper/NodeWrapper';

const PipeCurve = memo((props) => {
  const { id, data } = props;

  console.log(`${id} node rendered`);

  return (
    <NodeWrapper {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlSpace='preserve'
        width={data?.common?.size?.width ?? 80}
        height={data?.common?.size?.height ?? 80}
        viewBox='0 0 112.5 112.5'
        {...props}
      >
        <linearGradient
          id='a'
          x1={36.116}
          x2={96.603}
          y1={90.236}
          y2={90.236}
          gradientTransform='matrix(-1 0 0 1 96.603 -25.219)'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0.01}
            style={{
              stopColor: '#006400',
            }}
          />
          <stop
            offset={0.18}
            style={{
              stopColor: '#299429',
            }}
          />
          <stop
            offset={0.5}
            style={{
              stopColor: '#bfdfbf',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#006500',
            }}
          />
        </linearGradient>
        <path fill='url(#a)' d='M0 62.271h60.486v5.491H0z' />
        <linearGradient
          id='b'
          x1={25.06}
          x2={25.06}
          y1={25.219}
          y2={80.426}
          gradientTransform='matrix(-1 0 0 1 96.603 -25.219)'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0.01}
            style={{
              stopColor: '#006400',
            }}
          />
          <stop
            offset={0.16}
            style={{
              stopColor: '#1f8f1f',
            }}
          />
          <stop
            offset={0.49}
            style={{
              stopColor: '#bfdfbf',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#006400',
            }}
          />
        </linearGradient>
        <path fill='url(#b)' d='M69.408 0h4.269v55.207h-4.269z' />
        <radialGradient
          id='c'
          cx={140.081}
          cy={319.221}
          r={23.779}
          gradientTransform='matrix(-2.9866 .2001 .2179 2.719 416.363 -834.625)'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0}
            style={{
              stopColor: '#005800',
            }}
          />
          <stop
            offset={0.1}
            style={{
              stopColor: '#006500',
            }}
          />
          <stop
            offset={0.22}
            style={{
              stopColor: '#0f870f',
            }}
          />
          <stop
            offset={0.4}
            style={{
              stopColor: '#7bbd7b',
            }}
          />
          <stop
            offset={0.53}
            style={{
              stopColor: '#bfdfbf',
            }}
          />
          <stop
            offset={0.64}
            style={{
              stopColor: '#85c285',
            }}
          />
          <stop
            offset={0.89}
            style={{
              stopColor: '#070',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#005300',
            }}
          />
        </radialGradient>
        <path
          fill='url(#c)'
          d='M71.319 0C36.547 0 0 21.826 0 65.055l60.45.162c1.263-7.605 4.71-9.44 10.676-9.932C71.827 40.793 71.319 0 71.319 0z'
        />
        <linearGradient
          id='d'
          x1={36.116}
          x2={96.603}
          y1={113.197}
          y2={113.197}
          gradientTransform='matrix(-1 0 0 1 96.603 -25.219)'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0.01}
            style={{
              stopColor: '#060',
            }}
          />
          <stop
            offset={0.18}
            style={{
              stopColor: '#259225',
            }}
          />
          <stop
            offset={0.5}
            style={{
              stopColor: '#bfdfbf',
            }}
          />
          <stop
            offset={0.74}
            style={{
              stopColor: '#51a851',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#006500',
            }}
          />
        </linearGradient>
        <path fill='url(#d)' d='M0 63.209h60.486v49.54H0z' />
        <linearGradient
          id='e'
          x1={4.818}
          x2={4.818}
          y1={25.275}
          y2={80.482}
          gradientTransform='matrix(-1 0 0 1 96.603 -25.219)'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0.01}
            style={{
              stopColor: '#006400',
            }}
          />
          <stop
            offset={0.16}
            style={{
              stopColor: '#1f8f1f',
            }}
          />
          <stop
            offset={0.49}
            style={{
              stopColor: '#bfdfbf',
            }}
          />
          <stop
            offset={0.75}
            style={{
              stopColor: '#45a245',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#006400',
            }}
          />
        </linearGradient>
        <path fill='url(#e)' d='M70.908.056h41.752v55.207H70.908z' />
      </svg>
    </NodeWrapper>
  );
});

export default PipeCurve;

PipeCurve.propTypes = {
  id: PropTypes.any,
  data: PropTypes.object,
};

PipeCurve.displayName = 'PipeCurve';
