import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Lock, LockOpen, Remove, Visibility, VisibilityOff } from '@mui/icons-material';

const NodeListItem = memo((props) => {
  const { layerItem, handleNodeSelect, onChangeNodeVisible, onChangeNodeLock } = props;

  // console.log(`${layerItem.id} rendered!`);


  return (
      <ListItemButton
        components='button'
        sx={{
          pl: 2.5,
          py: 0,
          minHeight: 32,
          backgroundColor: layerItem.selected ? '#d4e6fc' : 'none',
          cursor: 'pointer'
        }}
        onClick={(event) => {
          handleNodeSelect(event, layerItem);
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 23
          }}
        >
          <Remove
            fontSize='small'
          />
        </ListItemIcon>
        <ListItemText primary={layerItem.id}/>
        <>
          <IconButton
           edge="end"
           aria-label="visibility"
           onClick={(e) => {
             e.stopPropagation();
             onChangeNodeVisible(layerItem);
           }}
          >
           {
             !layerItem.hidden ? <Visibility
               fontSize='small'
             /> : <VisibilityOff
               fontSize='small'
             />
           }
          </IconButton>
          <IconButton
           edge="end"
           aria-label="lockable"
           onClick={(e) => {
             e.stopPropagation();
             onChangeNodeLock(layerItem);
           }}
          >
           {
             layerItem.draggable ? <LockOpen
               fontSize='small'
             /> : <Lock
               fontSize='small'
             />
           }
          </IconButton>
        </>
      </ListItemButton>
  );
});

export default NodeListItem;

NodeListItem.propTypes = {
  layerItem: PropTypes.object,
  handleNodeSelect: PropTypes.func,
  onChangeNodeVisible: PropTypes.func,
  onChangeNodeLock: PropTypes.func,
};

NodeListItem.displayName = 'NodeListItem';