import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import Wrapper from './Wrapper';

const PreviewWrapper = (props) => {
  const { id, type, data, position, hidden, zIndex, children } = props;
  const [node, setNode] = useState({ id, type, data, position, hidden, zIndex });
  const firstHit = useRef(false);

  useEffect(() => {

    if (!firstHit.current) {
      firstHit.current = true;
      return;
    }

    setNode({
      id, type, data, position, hidden, zIndex
    });

  }, [id, type, data, position, hidden, zIndex]);

  return (
    <Wrapper
      node={node}
    >
      {children}
    </Wrapper>
  )
}

export default PreviewWrapper;

PreviewWrapper.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.object,
  position: PropTypes.object,
  hidden: PropTypes.bool,
  zIndex: PropTypes.number,
  children: PropTypes.any
};