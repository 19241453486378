import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import NodeWrapper from '../../wrapper/NodeWrapper';

const Gauge = memo((props) => {
  const { id, data } = props;

  console.log(`${id} node rendered`);

  return (
    <NodeWrapper {...props}>
      <ReactEcharts
        option={{
          tooltip: {
            formatter: '{a} <br/>{b} : {c}%',
          },
          series: [
            {
              name: 'Pressure',
              type: 'gauge',
              progress: {
                show: true,
              },
              detail: {
                valueAnimation: false,
                formatter: '{value}',
              },
              data: [
                {
                  value: 50,
                  name: 'SCORE',
                },
              ],
            },
          ],
        }}
        opts={{ 
          width: data?.common?.size?.width ?? 80,
          height: data?.common?.size?.height ?? 80
         }}
      />
    </NodeWrapper>
  );
});

export default Gauge;

Gauge.propTypes = {
  id: PropTypes.any,
  data: PropTypes.object,
};

Gauge.displayName = 'Gauge';
