import React, { memo } from "react";
import PropTypes from "prop-types";
import NodeWrapper from "../../wrapper/NodeWrapper";
import {Button} from "@mui/material";

const Buttons = memo((props) => {
  const { id, data } = props;

  console.log(`${id} node rendered`);

  return (
    <NodeWrapper {...props} >
      <Button
        variant="contained"
      >
        Button
      </Button>
    </NodeWrapper>
  )
});

export default Buttons;

Buttons.propTypes = {
  id: PropTypes.any,
  data: PropTypes.object,
};

Buttons.displayName = 'Buttons';
