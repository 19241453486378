import React, { useState } from 'react';
import { Box, Divider, IconButton, Stack } from '@mui/material';
import {
  GetApp,
  Help,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  Notifications,
  Preview,
  Publish,
  Redo,
  Settings,
  Undo,
  ZoomIn,
  ZoomOut
} from '@mui/icons-material';
import useFlowground from '../../../utility/hooks/useFlowground';
import { useNavigate } from 'react-router-dom';
import FlowgroundSettingModal from '../modals/FlowgroundSettingModal';

const TopSidebar = () => {
  const {
    layerSidebarWidth,
    isOpenLayerSidebar,
    setIsOpenLayerSidebar,
    isOpenGeneralSidebar,
    setIsOpenGeneralSidebar,
    setFlowgroundWidth,
    flowgroundZoomIn,
    flowgroundZoomOut,
  } = useFlowground();
  const navigate = useNavigate();

  const [isOpenSettings, setIsOpenSettings] = useState(false);

  return (
    <>
      <Box sx={{ flexGrow: 1, backgroundColor: '#f1f3f4' }}>
        <Stack spacing={1} direction='row' sx={{ justifyContent: 'space-between' }}>
          <Stack spacing={1} direction='row'>
            <IconButton
              aria-label="preview"
              onClick={() => {
                navigate('preview')
              }}
            >
              <Preview/>
            </IconButton>
            <Divider orientation="vertical" flexItem/>
            <IconButton aria-label="undo">
              <Undo/>
            </IconButton>
            <IconButton aria-label="redo">
              <Redo/>
            </IconButton>
            <Divider orientation="vertical" flexItem/>
            <IconButton
              aria-label="zoom in"
              onClick={flowgroundZoomIn}
            >
              <ZoomIn/>
            </IconButton>
            <IconButton
              aria-label="zoom out"
              onClick={flowgroundZoomOut}
            >
              <ZoomOut/>
            </IconButton>
            <Divider orientation="vertical" flexItem/>
            <IconButton aria-label="import">
              <GetApp/>
            </IconButton>
            <IconButton aria-label="export">
              <Publish/>
            </IconButton>
            <Divider orientation="vertical" flexItem/>
            <IconButton
              aria-label="react flow settings"
              onClick={() => {
                setIsOpenSettings(true);
              }}
            >
              <Settings/>
            </IconButton>
            <IconButton aria-label="notification">
              <Notifications/>
            </IconButton>
            <IconButton aria-label="help">
              <Help/>
            </IconButton>
          </Stack>
          <Stack spacing={1} direction='row'>
            <IconButton
              aria-label="delete"
              onClick={() => {
                setIsOpenLayerSidebar((open) => !open);

                if (isOpenGeneralSidebar === false) {
                  setFlowgroundWidth(window.innerWidth);
                } else {
                  setFlowgroundWidth(window.innerWidth - layerSidebarWidth);
                }
              }}
            >
              {
                isOpenLayerSidebar ? (
                  <KeyboardDoubleArrowLeft/>
                ) : (
                  <KeyboardDoubleArrowRight/>
                )
              }
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => {
                setIsOpenGeneralSidebar((open) => !open);

                if (isOpenLayerSidebar === false) {
                  setFlowgroundWidth(window.innerWidth);
                } else {
                  setFlowgroundWidth(window.innerWidth - layerSidebarWidth);
                }
              }}
            >
              {
                isOpenGeneralSidebar ? (
                  <KeyboardDoubleArrowRight/>
                ) : (
                  <KeyboardDoubleArrowLeft/>
                )
              }
            </IconButton>
          </Stack>
        </Stack>
      </Box>
      {
        isOpenSettings && (
          <FlowgroundSettingModal
            isOpenSettings={isOpenSettings}
            setIsOpenSettings={setIsOpenSettings}
          />
        )
      }
    </>
  )
}

export default TopSidebar;