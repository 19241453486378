import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Wrapper from './Wrapper';
import useFlowground from '../../../../utility/hooks/useFlowground';

const FlowgroundWrapper = (props) => {
  const { id, type, data, selected, xPos, yPos, zIndex, children } = props;
  const { handleOnResizeEnd } = useFlowground();

  return (
    <Wrapper
      id={id}
      type={type}
      data={data}
      selected={selected}
      xPos={xPos}
      yPos={yPos}
      zIndex={zIndex}
      handleOnResizeEnd={handleOnResizeEnd}
    >
      {children}
    </Wrapper>
  )
}

export default FlowgroundWrapper;

FlowgroundWrapper.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.object,
  selected: PropTypes.bool,
  xPos: PropTypes.number,
  yPos: PropTypes.number,
  zIndex: PropTypes.number,
  children: PropTypes.any,
}
