import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const PositionBox = (props) => {
  const { xPos, yPos, data} = props;

  const positionBoxWidth = 60;
  const calculatePositionBox = (nodeWidth) => {
    return (nodeWidth - positionBoxWidth) / 2;
  }

  return (
    <Box
      sx={{
        border: '1px solid #a9a9a9',
        backgroundColor: '#ebebeb',
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        fontSize: 12,
        color: '#4a4a4a',
        marginTop: '1.5px',
        position: 'absolute',
        width: positionBoxWidth,
        left: calculatePositionBox(data?.common?.size?.width)
      }}
    >
      {`${xPos} ${yPos}`}
    </Box>
  );
};

export default PositionBox;

PositionBox.propTypes = {
  xPos: PropTypes.number,
  yPos: PropTypes.number,
  data: PropTypes.object
}