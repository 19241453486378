// eslint-disable-next-line react/prop-types
import React, { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box } from '@mui/material';
import PositionBox from '../../helpers/PositionBox';
import { NodeResizer } from 'reactflow';

const Wrapper = memo((props) => {
  const { id, type, data, selected, xPos, yPos, zIndex, handleOnResizeEnd, children } = props;
  const wrapperRef = useRef(null);

  let outlineText = 'none';
  if (selected) {
    if (data?.properties?.draggable === false) {
      outlineText = '1px solid red';
    }
  }

  return (
    <>
        {
          (selected === true && data?.properties?.draggable) && ( //&& !selectionEvent
            <NodeResizer
              nodeId={id}
              color={'#1976d2'}
              isVisible={selected}
              minWidth={30}
              minHeight={30}
              onResizeEnd={(event, params) => {
                handleOnResizeEnd(event, id, params);
              }}
              lineStyle={{
                zIndex: 5000
              }}
            />
          )
        }
        <Box
          // component='span'
          sx={{
            outline: outlineText,
            boxSizing: 'content-box',
            width: data?.common?.size?.width,
            height: data?.common?.size?.height,
            transform: `rotate(${data?.common?.rotate || 0}deg)`,
            opacity: data?.common?.opacity || 1
          }}
          ref={wrapperRef}
        >
          {children}
        </Box>
      {
        (selected) && ( //&& !selectionEvent
          <PositionBox
            xPos={xPos}
            yPos={yPos}
            data={data}
          />
        )
      }
    </>
  )
});

export default Wrapper;

Wrapper.propTypes = {
  node: PropTypes.object,
  id: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.object,
  selected: PropTypes.bool,
  xPos: PropTypes.number,
  yPos: PropTypes.number,
  zIndex: PropTypes.number,
  handleOnResizeEnd: PropTypes.func,
  children: PropTypes.any,
};

Wrapper.displayName = 'Wrapper';
