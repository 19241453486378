import React, { memo } from "react";
import PropTypes from "prop-types";
import NodeWrapper from "../../wrapper/NodeWrapper";

const Text = memo((props) => {
  const { id, data } = props;

  // console.log(`${id} node rendered`);
  // console.log({
  //   props
  // });

  return (
    <NodeWrapper {...props} >
      <>
        TEST
      </>
    </NodeWrapper>
  )
});

export default Text;

Text.propTypes = {
  id: PropTypes.any,
  data: PropTypes.object,
}

Text.displayName = 'Text';