import { Button, AppBar, Box, Toolbar, Typography, Stack } from "@mui/material";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import menu from "../config/menu";

const SimpleLayout = () => {
  const navigate = useNavigate();

  return (
    <>
      <Outlet />
    </>
  );
};

export default SimpleLayout;
