import React, { memo } from "react";
import PropTypes from "prop-types";
import NodeWrapper from "../../wrapper/NodeWrapper";

const Rectangle = memo((props) => {
  const { id, data } = props;

  console.log(`${id} node rendered`);

  return (
    <NodeWrapper {...props} >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={data?.common?.size?.width ?? 80}
          height={data?.common?.size?.height ?? 80}
          fill="#000"
          version="1.1"
          viewBox="0 0 512 512"
          xmlSpace="preserve"
        >
          <path
            d="M501.333 96H10.667C4.779 96 0 100.779 0 106.667v298.667C0 411.221 4.779 416 10.667 416h490.667c5.888 0 10.667-4.779 10.667-10.667V106.667C512 100.779 507.221 96 501.333 96zm-10.666 298.667H21.333V117.333h469.333v277.334z"></path>
        </svg>
      </div>
    </NodeWrapper>
  )
});

export default Rectangle;

Rectangle.propTypes = {
  id: PropTypes.any,
  data: PropTypes.object
};

Rectangle.displayName = 'Rectangle';

