import React from 'react';
import { CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import Router from './config/routes';

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <CssBaseline enableColorScheme />
        <Router />
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
