import React, { memo } from "react";
import PropTypes from "prop-types";
import NodeWrapper from "../../wrapper/NodeWrapper";

const Circle = memo((props) => {
  const { id, data } = props;

  console.log(`${id} node rendered`);

  return (
    <NodeWrapper {...props} >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={data?.common?.size?.width ?? 80}
        height={data?.common?.size?.height ?? 80}
        fill="#0000FF"
        version="1.1"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
      >
        <path
          d="M256 0C114.837 0 0 114.837 0 256s114.837 256 256 256 256-114.837 256-256S397.163 0 256 0zm0 490.667c-129.387 0-234.667-105.28-234.667-234.667S126.613 21.333 256 21.333 490.667 126.613 490.667 256 385.387 490.667 256 490.667z"></path>
      </svg>
    </NodeWrapper>
  )
});

export default Circle;

Circle.propTypes = {
  id: PropTypes.any,
  data: PropTypes.object,
};

Circle.displayName = 'Circle';
