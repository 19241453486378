import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import { Box } from '@mui/material';

const Wrapper = memo((props) => {
  const { node, children } = props;

  console.log({
    node
  });

  return (
    <Box
      sx={{
        transform: `translate(${node?.position?.x}px, ${node?.position?.y}px) rotate(${node?.data?.common?.rotate || 0}deg)`,
        position: 'absolute',
        zIndex: node?.zIndex,
        opacity: node?.data?.common?.opacity || 1,
      }}
      onClick={() => {
        console.log({
          props
        });
      }}
    >
      {children}
    </Box>
  )
});

export default Wrapper;

Wrapper.propTypes = {
  node: PropTypes.object,
  children: PropTypes.any
}

Wrapper.displayName = 'Wrapper';
