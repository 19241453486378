import React, { memo } from "react";
import PropTypes from "prop-types";
import NodeWrapper from "../../wrapper/NodeWrapper";

const Pentagon = memo((props) => {
    const { id, data } = props;

    console.log(`${id} node rendered`);

    return (
      <NodeWrapper {...props} >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={data?.common?.size?.width ?? 80}
            height={data?.common?.size?.height ?? 80}
            fill="#000"
            version="1.1"
            viewBox="0 0 511.995 511.995"
            xmlSpace="preserve"
          >
            <path
              d="M507.807 200.272L262.474 12.859a10.657 10.657 0 00-12.949 0L4.191 200.272a10.663 10.663 0 00-3.648 11.84l93.717 281.92a10.695 10.695 0 0010.133 7.296h303.253c4.608 0 8.683-2.944 10.091-7.296l93.717-281.92c1.431-4.309-.041-9.066-3.647-11.84zM399.946 479.995H112.095L23.221 212.582l232.81-177.835 232.811 177.856-88.896 267.392z"></path>
          </svg>
        </div>
      </NodeWrapper>
    )
});

export default Pentagon;

Pentagon.propTypes = {
  id: PropTypes.any,
  data: PropTypes.object
};

Pentagon.displayName = 'Pentagon';
