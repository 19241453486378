import React, { memo } from "react";
import PropTypes from "prop-types";
import NodeWrapper from "../../wrapper/NodeWrapper";
import {Switch} from "@mui/material";

const Switchs = memo((props) => {
  const { id, data } = props;

  console.log(`${id} node rendered`);

  return (
    <NodeWrapper {...props} >
      <Switch defaultChecked />
    </NodeWrapper>
  )
});

export default Switchs;

Switchs.propTypes = {
  id: PropTypes.any,
  data: PropTypes.object,
};

Switchs.displayName = 'Switchs';
