import React from "react";
import PropTypes from "prop-types";
import FlowgroundWrapper from './flowground/FlowgroundWrapper';
import PreviewWrapper from './preview/PreviewWrapper';
import useFlowground from '../../../utility/hooks/useFlowground';

const NodeWrapper = (props) => {
  const { isPreview, children } = props;
  // const { handleOnResizeEnd } = useFlowground();

  return (
    <>
      {
        isPreview ? (
            <PreviewWrapper {...props}>
              {children}
            </PreviewWrapper>
        ) : (
          <FlowgroundWrapper
            {...props}
            // handleOnResizeEnd={handleOnResizeEnd}
          >
            {children}
          </FlowgroundWrapper>
        )
      }
    </>
  )
};

export default NodeWrapper;

NodeWrapper.propTypes = {
  isPreview: PropTypes.bool,
  children: PropTypes.any
};
