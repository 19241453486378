import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import NodeWrapper from '../../wrapper/NodeWrapper';

const Chart = memo((props) => {
  const { id, data } = props;

  console.log(`${id} node rendered`);

  const options = {
    xAxis: {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: [150, 230, 224, 218, 135, 147, 260],
        type: 'line',
      },
    ],
  };

  return (
    <NodeWrapper {...props}>
      <ReactEcharts
        option={options}
        style={{
          width: data?.common?.size?.width ?? 80,
          height: data?.common?.size?.height ?? 80,
        }}
      />
    </NodeWrapper>
  );
});

export default Chart;

Chart.propTypes = {
  id: PropTypes.any,
  data: PropTypes.object,
};

Chart.displayName = 'Chart';
