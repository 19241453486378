import Frame from "../components/node/types/Frame";
import Circle from "../components/node/types/shapes/Circle";
import Pentagon from "../components/node/types/shapes/Pentagon";
import Rectangle from "../components/node/types/shapes/Rectangle";
import Square from "../components/node/types/shapes/Square";
import Triangle from "../components/node/types/shapes/Triangle";
import Text from "../components/node/types/elements/Text";
import HorizontalLine from '../components/node/types/helperLines/Horizontal';
import Pipe from "../components/node/types/symbolFactory/Pipe";
import PipeCurve from "../components/node/types/symbolFactory/PipeCurve";
import Pump from "../components/node/types/symbolFactory/Pump";
import Tank from "../components/node/types/symbolFactory/Tank";
import Machine from "../components/node/types/symbolFactory/Machine";
import Panel from "../components/node/types/symbolFactory/Panel";
import Gauge from "../components/node/types/gauges/Gauge";
import Chart from "../components/node/types/charts/Chart";
import Map from "../components/node/types/map/Map";
import DataTable from "../components/node/types/elements/DataTable";
import Buttons from "../components/node/types/elements/Button";
import Switchs from "../components/node/types/elements/Switchs";
import CheckBoxes from "../components/node/types/elements/Checkboxes";

const availableNodeTypes = {
  frame: Frame,
  circle: Circle,
  square: Square,
  triangle: Triangle,
  rectangle: Rectangle,
  pentagon: Pentagon,
  text: Text,
  horizontalLine: HorizontalLine,
  pipe: Pipe,
  pipeCurve: PipeCurve,
  pump: Pump,
  tank: Tank,
  machine: Machine,
  panel: Panel,
  gauge: Gauge,
  chart: Chart,
  map: Map,
  table: DataTable,
  button: Buttons,
  switch: Switchs,
  checkbox: CheckBoxes,
};

export default availableNodeTypes;
