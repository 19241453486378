import React, { useEffect, useState } from "react";
import useFlowground from "../../../utility/hooks/useFlowground";
import { Box, Divider } from "@mui/material";
import flowgroundSectionSizes from '../../../config/flowgroundSectionSizes';
import SelectedNodeTabs from '../shared/generalSidebar/SelectedNodeTabs';
import SelectedNodeContents from '../shared/generalSidebar/SelectedNodeContents';

const GeneralSidebar = () => {
  const {
    generalSidebarWidth,
    flowgroundHeight,
    nodesSetting,
    nodeHandleSetting
  } = useFlowground();

  // const [ selectedNodes, setSelectedNodes ] = useState([]);
  const [ selectedTabIndex, setSelectedTabIndex ] = useState(0);

  useEffect(() => {
    setSelectedTabIndex(0);
  }, [nodesSetting]);

  return (
    <>
      <Box
        sx={{
          padding: '0!important',
          display: 'flex',
          alignContent: 'flex-start',
          flexWrap: 'wrap',
        }}
      >
        <Box
          sx={{
            padding: 0,
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: '0.875rem',
            fontWeight: 500,
            width: generalSidebarWidth,
            height: flowgroundSectionSizes?.sidebarHeaderHeight,
            boxSizing: 'border-box'
          }}
        >
          <SelectedNodeTabs
            selectedNodes={nodesSetting}
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
          />
        </Box>
        <Divider
          sx={{
            width: generalSidebarWidth
          }}
        />
        <Box
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            height: flowgroundHeight - flowgroundSectionSizes?.sidebarHeaderHeight - 2,
            width: generalSidebarWidth
          }}
        >
          <Divider
            sx={{
              width: '100%'
            }}
          />
          <SelectedNodeContents
            selectedNodes={nodesSetting}
            selectedTabIndex={selectedTabIndex}
            nodeHandleSetting={nodeHandleSetting}
          />
        </Box>
      </Box>
    </>
  )
};

export default GeneralSidebar;