import React, { memo } from "react";
import PropTypes from "prop-types";
import NodeWrapper from "../../wrapper/NodeWrapper";

const Square = memo((props) => {
  const { id, data } = props;

  console.log(`${id} node rendered`);

  return (
    <NodeWrapper {...props} >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={data?.common?.size?.width ?? 80}
          height={data?.common?.size?.height ?? 80}
          fill="#000"
          version="1.1"
          viewBox="0 0 512 512"
          xmlSpace="preserve"
        >
          <path
            d="M501.333 0H10.667C4.779 0 0 4.779 0 10.667v490.667C0 507.221 4.779 512 10.667 512h490.667c5.888 0 10.667-4.779 10.667-10.667V10.667C512 4.779 507.221 0 501.333 0zm-10.666 490.667H21.333V21.333h469.333v469.334z"></path>
        </svg>
      </div>
    </NodeWrapper>
  )
});

export default Square;

Square.propTypes = {
  id: PropTypes.any,
  data: PropTypes.object
}

Square.displayName = 'Square';
