
const nodeDefaultValues = [
  {
    type: 'frame',
    data: {
      width: 1920,
      height: 1080,
      backgroundColor: '#f1f3f4',
      opacity:  0.3,
      backgroundImage: null,
    }
  },
  {
    type: 'circle',
    data: {
      width: 80,
      height: 80,
      xPos: 0,
      yPos: 0
    }
  },
  {
    type: 'square',
    data: {
      width: 80,
      height: 80,
      xPos: 0,
      yPos: 0
    }
  },
  {
    type: 'rectangle',
    data: {
      width: 80,
      height: 80,
      xPos: 0,
      yPos: 0
    }
  },
  {
    type: 'triangle',
    data: {
      width: 80,
      height: 80,
      xPos: 0,
      yPos: 0
    }
  },
  {
    type: 'pentagon',
    data: {
      width: 80,
      height: 80,
      xPos: 0,
      yPos: 0
    }
  }
];

export default nodeDefaultValues;