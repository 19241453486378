import React from "react";
import { FlowgroundProvider } from "../../contexts/FlowgroundContext";
import FlowgroundComponent from "../../components/flowground/FlowgroundComponent";
import { ReactFlowProvider } from 'reactflow';

const Flowground = () => {

  return (
    <ReactFlowProvider>
      <FlowgroundProvider>
        <FlowgroundComponent/>
      </FlowgroundProvider>
    </ReactFlowProvider>
  )
}

export default Flowground;
