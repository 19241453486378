import React, { useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useFlowground from '../../../utility/hooks/useFlowground';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField
} from '@mui/material';
import { generateRandomNumber } from '../../../helpers/generator';
import nodeDefaultValues from '../../../config/nodeDefaultValues';

const nodeList = [
  {
    value: 'circle',
    label: 'Circle',
  },
  {
    value: 'square',
    label: 'Square',
  },
  {
    value: 'rectangle',
    label: 'Rectangle',
  },
  {
    value: 'triangle',
    label: 'Triangle',
  },
  {
    value: 'pentagon',
    label: 'Pentagon',
  },
  {
    value: 'text',
    label: 'Text',
  },
  {
    value: 'pipe',
    label: 'Pipe',
  },
  {
    value: 'pipeCurve',
    label: 'Pipe Curve',
  },
  {
    value: 'pump',
    label: 'Pump',
  },
  {
    value: 'tank',
    label: 'Tank',
  },
  {
    value: 'machine',
    label: 'Machine',
  },
  {
    value: 'panel',
    label: 'Panel',
  },
  {
    value: 'gauge',
    label: 'Gauge',
  },
  {
    value: 'chart',
    label: 'Chart',
  },
  {
    value: 'map',
    label: 'Map',
  },
  {
    value: 'table',
    label: 'Table'
  },
  {
    value: 'button',
    label: 'Button'
  },
  {
    value: 'switch',
    label: 'Switch'
  },
  {
    value: 'checkbox',
    label: 'Checkbox'
  }
];

const useStyles = makeStyles(({
  helperText: {
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-1.2rem'
    }
  }
}));

const NodeAddModal = () => {
  const { handleCreateNode, toggleNodeAddModal, setToggleNodeAddModal } = useFlowground();
  const formRef = useRef(null);
  const classes = useStyles();
  const [disableInputs, setDisableInputs] = useState(true);

  const handleClose = () => {
    setToggleNodeAddModal(false);
  }

  const handleSubmit = () => {
    // console.log(formRef.current);
    formRef.current.submitForm();
  }

  const handleChangeNodeType = (e, setFieldValue) => {
    const nodeType = e.target.value;
    const nodeDefaultValue = nodeDefaultValues.find((_default) => {
      return _default.type === nodeType
    });

    setFieldValue('type', e.target.value);
    setFieldValue('name', `${e.target.value}_${generateRandomNumber(1000, 9999)}`);
    setFieldValue('xPos', nodeDefaultValue?.data?.xPos ?? 0);
    setFieldValue('yPos', nodeDefaultValue?.data?.yPos ?? 0);
    setFieldValue('width', nodeDefaultValue?.data?.width ?? 80);
    setFieldValue('height', nodeDefaultValue?.data?.height ?? 80);
    setDisableInputs(false);
  }

  const onSubmitForm = (values) => {
    const newNodeData = {
      name: values.name,
      type: values.type,
      xPos: values.xPos ?? 0,
      yPos: values.yPos ?? 0,
      width: values.width ?? 80,
      height: values.height ?? 80,
    };
    handleCreateNode(newNodeData);
    handleClose();
  }

  return (
    <>
      <Dialog
        open={toggleNodeAddModal}
        onClose={handleClose}
      >
        <DialogTitle>Node Add</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: 500
            }}
          >
            <Formik
              initialValues={{
                name: null,
                type: null,
                xPos: 0,
                yPos: 0,
                width: null,
                height: null
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required('Name is required'),
                type: Yup.string().required('Node list is required'),
              })}
              onSubmit={onSubmitForm}
              innerRef={formRef}
            >
              {({ values, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={0.5}
                  >
                    <Grid
                      item
                      md={12}
                    >
                      <TextField
                        margin="dense"
                        select
                        label="Node List"
                        variant="outlined"
                        name="type"
                        size="small"
                        fullWidth
                        onChange={(e) => {
                          handleChangeNodeType(e, setFieldValue);
                        }}
                        value={values.type || ''}
                        error={Boolean(touched.type && errors.type)}
                        helperText={touched.type && errors.type}
                      >
                        {
                          nodeList.map((node) => {
                            return (
                              <MenuItem
                                key={`nodeAdd_${node.value}`}
                                value={node.value}
                              >
                                {node.label}
                              </MenuItem>
                            )
                          })
                        }
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      md={12}
                    >
                      <TextField
                        margin="dense"
                        label="Name"
                        variant="outlined"
                        name="name"
                        size="small"
                        fullWidth
                        onChange={handleChange}
                        value={values.name || ''}
                        className={classes.helperText}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        disabled={disableInputs}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                    >
                      <TextField
                        type="number"
                        margin="dense"
                        label="Position X"
                        variant="outlined"
                        name="xPos"
                        size="small"
                        fullWidth
                        onChange={handleChange}
                        value={values.xPos || ''}
                        disabled={disableInputs}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                    >
                      <TextField
                        type="number"
                        margin="dense"
                        label="Position Y"
                        variant="outlined"
                        name="yPos"
                        size="small"
                        fullWidth
                        onChange={handleChange}
                        value={values.yPos || ''}
                        disabled={disableInputs}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                    >
                      <TextField
                        type="number"
                        margin="dense"
                        label="Width"
                        variant="outlined"
                        name="width"
                        size="small"
                        fullWidth
                        onChange={handleChange}
                        value={values.width || ''}
                        disabled={disableInputs}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                    >
                      <TextField
                        type="number"
                        margin="dense"
                        label="Height"
                        variant="outlined"
                        name="height"
                        size="small"
                        fullWidth
                        onChange={handleChange}
                        value={values.height || ''}
                        disabled={disableInputs}
                      />
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type='button'
            variant='contained'
            onClick={handleSubmit}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
};

export default NodeAddModal;