import React, { memo } from 'react';
import PropTypes from 'prop-types';
import NodeWrapper from '../../wrapper/NodeWrapper';
import { FormControlLabel, Checkbox } from '@mui/material';

const CheckBoxes = memo((props) => {
  const { id, data } = props;

  console.log(`${id} node rendered`);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (
    <NodeWrapper {...props}>
      <FormControlLabel control={<Checkbox defaultChecked />} />
    </NodeWrapper>
  );
});

export default CheckBoxes;

CheckBoxes.propTypes = {
  id: PropTypes.any,
  data: PropTypes.object,
};

CheckBoxes.displayName = 'CheckBoxes';
