import React, { useEffect, useRef, useState } from "react";
import nodeTypes from '../../config/nodeTypes';
import { Box, IconButton } from '@mui/material';
import { Fullscreen } from '@mui/icons-material';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const Preview = () => {
  const handleFullScreen = useFullScreenHandle();
  const [frameProperties, setFrameProperties] = useState({});
  const [nodes, setNodes] = useState([]);
  const firstHit = useRef(false);

  useEffect(() => {
    if (!firstHit) {
      firstHit.current = true;
    }
    const getFrameProperties = localStorage.getItem('frameProperties');
    setFrameProperties(JSON.parse(getFrameProperties));

    const getNodes = localStorage.getItem('nodes');
    setNodes(JSON.parse(getNodes).map((node) => {
      return {
        ...node,
        component: nodeTypes[node.type]
      };
    }));
  }, []);

  return(
      <>
        <Box
          sx={{
            width: '100%',
            height: '4.3vh',
            backgroundColor: '#f1f3f4',
            border: '1px solid #dadce0'
          }}
        >
          <IconButton
            aria-label="fullscreen"
            onClick={handleFullScreen.enter}
          >
            <Fullscreen />
          </IconButton>
        </Box>
        <FullScreen
          handle={handleFullScreen}
          className='fullscreenContainer'
        >
          <Box
            sx={{
              width: frameProperties.width,
              height: frameProperties.height,
              boxSizing: 'border-box',
              background: '#fff',
              overflow: 'auto',
              position: 'relative',
            }}
          >
            {
              nodes?.map((node) => {
                return (
                  <node.component
                    key={`node${node.id}`}
                    isPreview
                    {...node}
                  />
                  // <Box
                  //   key={`node${node.id}`}
                  //   sx={{
                  //     transform: `translate(${node.position.x}px, ${node.position.y}px)`,
                  //     position: 'absolute',
                  //     zIndex: node.zIndex
                  //   }}
                  // >
                  //   <node.component
                  //     isPreview
                  //     id={node.id}
                  //     data={node.data}
                  //   />
                  // </Box>
                )
              })
            }
          </Box>
        </FullScreen>
      </>
  )
};

export default Preview;