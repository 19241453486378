const nodeColor = (node) => {
  // discard frame and group
  if (node.type === 'group' || node.type === 'frame') {
    return '#fff'
  }

  // selected true
  if (node.selected === true) {
    return '#9c27b0'
  }

  // draggable false
  if (node.draggable === false) {
    return '#d32f2f'
  }

  return '#1976d2';
};

export default nodeColor;