import { Divider, Menu, MenuItem } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import useFlowground from '../../../../utility/hooks/useFlowground';

const ContextMenu = () => {
  const { setNodes, setNodesLayer, setNodeIsSaved, toggleNodeLock, toggleNodeVisible, handleCloseContextMenu, isOpenContextMenu, contextMenuPosition, contextMenuNode } = useFlowground();

  const handleNodeBringToFront = (selectedNode) => {

    setNodes((nodes) => {
      return nodes.map((node) => {
        if (node.id === selectedNode.id) {
          return {
            ...node,
            zIndex: selectedNode.zIndex + 1,
          }
        }
        if (node.zIndex === selectedNode.zIndex + 1) {
          return {
            ...node,
            zIndex: node.zIndex - 1,
          }
        }
        return node;
      });
    });

    setNodesLayer((nodesLayer) => {
      return nodesLayer.map((nodeLayer) => {
        if (nodeLayer.id === selectedNode.id) {
          return {
            ...nodeLayer,
            zIndex: selectedNode.zIndex + 1,
          }
        }
        if (nodeLayer.zIndex === selectedNode.zIndex + 1) {
          return {
            ...nodeLayer,
            zIndex: nodeLayer.zIndex - 1
          }
        }
        return nodeLayer;
      });
    });

    setNodeIsSaved(true);
    handleCloseContextMenu();
  }

  const handleNodeBackToSend = (selectedNode) => {

    setNodes((nodes) => {
      return nodes.map((node) => {
        if (node.id === selectedNode.id) {
          return {
            ...node,
            zIndex: selectedNode.zIndex - 1,
          }
        }
        if (node.zIndex === selectedNode.zIndex - 1) {
          return {
            ...node,
            zIndex: node.zIndex + 1,
          }
        }
        return node;
      });
    });

    setNodesLayer((nodesLayer) => {
      return nodesLayer.map((nodeLayer) => {
        if (nodeLayer.id === selectedNode.id) {
          return {
            ...nodeLayer,
            zIndex: selectedNode.zIndex - 1,
          }
        }
        if (nodeLayer.zIndex === selectedNode.zIndex - 1) {
          return {
            ...nodeLayer,
            zIndex: nodeLayer.zIndex + 1
          }
        }
        return nodeLayer;
      });
    });

    setNodeIsSaved(true);
    handleCloseContextMenu();
  }

  return (
    <Menu
      open={isOpenContextMenu}
      onClose={handleCloseContextMenu}
      anchorReference='anchorPosition'
      anchorPosition={{
        top: contextMenuPosition.y,
        left: contextMenuPosition.x
      }}
      sx={{
        minWidth: '200px'
      }}
    >
      <MenuItem
        sx={{
          color: 'red'
        }}
      >
        Remove
      </MenuItem>
      <MenuItem
        sx={{
          minWidth: '200px'
        }}
      >
        Duplicate
      </MenuItem>
      <MenuItem>
        Copy
      </MenuItem>
      <MenuItem>
        Paste
      </MenuItem>
      <Divider
        sx={{
          width: '100%'
        }}
      />
      <MenuItem
        onClick={() => {
          handleNodeBringToFront(contextMenuNode);
        }}
      >
        Bring to Front
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleNodeBackToSend(contextMenuNode);
        }}
      >
        Send to Back
      </MenuItem>
      <Divider
        sx={{
          width: '100%'
        }}
      />
      <MenuItem
        onClick={() => {
          toggleNodeLock(contextMenuNode);
          handleCloseContextMenu();
        }}
      >
        Lock/Unlock
      </MenuItem>
      <MenuItem
        onClick={() => {
          toggleNodeVisible(contextMenuNode);
          handleCloseContextMenu();
        }}
      >
        Show/Hide
      </MenuItem>
      <Divider
        sx={{
          width: '100%'
        }}
      />
      <MenuItem>
        Advanced
      </MenuItem>
      <MenuItem>
        Datasource
      </MenuItem>
      <MenuItem>
        Actions
      </MenuItem>
    </Menu>
  );
}

export default ContextMenu;
