import nodeDefaultValues from "../../config/nodeDefaultValues";

const initialFlowground = (props) => {
  const { get: { frameProperties }, set: { setNodes, setFrameProperties, setNodeCurrentZIndex, setNodesLayer, setFlowgroundSettings } } = props;


  // default frame value
  const defaultFrameValue = nodeDefaultValues.filter((_value) => {
    if (_value.type === 'frame') {
      return _value.data;
    }
  })[0]?.data;

  // prepare frame nodes
  const frameNodesPrepare = [
    {
      id: 'workspace',
      type: 'group',
      data: { label: null },
      position: { x: 0, y: 0 },
      style: {
        width: frameProperties.width,
        height: frameProperties.height,
        visibility: 'hidden',
      },
      zIndex: 1,
      draggable: false,
      selectable: false,
    },
    {
      id: 'frame',
      type: 'frame',
      position: { x: 0, y: 0 },
      parentNode: 'workspace',
      extent: 'parent',
      draggable: false,
      selectable: false,
      zIndex: 2,
      data: {
        width: frameProperties?.width ?? defaultFrameValue?.width,
        height: frameProperties?.height ?? defaultFrameValue?.height,
        backgroundColor: frameProperties?.backgroundColor ?? defaultFrameValue?.backgroundColor,
        opacity: frameProperties?.opacity ?? defaultFrameValue?.opacity,
        backgroundImage: frameProperties?.backgroundImage ?? defaultFrameValue?.backgroundImage
      }
    },
  ];

  // get nodes from local storage
  const localStorageNodeList = localStorage.getItem('nodes') ?? `[]`;
  const nodeList = JSON.parse(localStorageNodeList);

  // current node zIndex
  const nodeZIndexList = nodeList.map((node) => {
    if (node.zIndex >= 1000) {
      return node.zIndex;
    }
  }).filter(Boolean);
  if (nodeZIndexList.length > 0) {
    setNodeCurrentZIndex(Math.max.apply(0, nodeZIndexList));
  } else {
    setNodeCurrentZIndex(999);
  }

  // set nodes
  setNodes([...frameNodesPrepare, ...nodeList]);

  // set frame properties
  setFrameProperties(frameProperties);

  // set nodes layer
  const nodesLayerData = nodeList.map((node) => {
    return {
      id: node?.id ?? null,
      type: node?.type ?? null,
      name: node?.data?.name ?? '',
      zIndex: node?.zIndex,
      selected: node?.selected ?? false,
      hidden: node?.hidden ?? false,
      draggable: node?.draggable ?? true,
    };
  });
  setNodesLayer(nodesLayerData);

  setFlowgroundSettings({
    backgroundVariant: 'lines',
    minimap: true,
    zoom: true,
    fitOnView: true
  });
};

export default initialFlowground;