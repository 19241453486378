import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Tab, Tabs } from '@mui/material';

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  // backgroundColor: 'red',
  minHeight: 0,
  maxHeight: 40,
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
  '& .MuiTabs-scrollButtons': {
    width: 24,
  },
  '& .MuiTabs-scrollButtons.Mui-disabled': {
    opacity: 0.3,
  }
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [ theme.breakpoints.up('sm') ]: {
    minWidth: 0,
  },
  top: -5,
  fontWeight: 500,
  fontSize: '0.875rem',
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.6)',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    color: '#40a9ff',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#1890ff',
    fontWeight: 500,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

const SelectedNodeTabs = memo((props) => {
  const { selectedNodes, selectedTabIndex, setSelectedTabIndex } = props;

  const handleChange = (event, index) => {
    setSelectedTabIndex(index);
  };

  if (selectedNodes.length > 0) {
    return (
      <AntTabs
        value={selectedTabIndex || 0}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {
          selectedNodes.map((selectedNode, selectedNodeIndex) => {
            return (
              <AntTab
                key={`generalTab_${selectedNodeIndex}`}
                label={selectedNode?.data?.name || selectedNode?.id}
              />
            )
          })
        }
      </AntTabs>
    );
  } else {
    return (
      <AntTabs
        value={0}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <AntTab label="Frame"/>
      </AntTabs>
    );
  }
});

export default SelectedNodeTabs;

SelectedNodeTabs.propTypes = {
  selectedNodes: PropTypes.array,
  selectedTabIndex: PropTypes.number,
  setSelectedTabIndex: PropTypes.func,
}

SelectedNodeTabs.displayName = 'SelectedNodeTabs';