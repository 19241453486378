import React, { memo } from 'react';
import PropTypes from 'prop-types';
import NodeWrapper from '../../wrapper/NodeWrapper';

const Panel = memo((props) => {
  const { id, data } = props;

  console.log(`${id} node rendered`);

  return (
    <NodeWrapper {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={data?.common?.size?.width ?? 80}
        height={data?.common?.size?.height ?? 80}
        viewBox='0 0 999 999'
        {...props}
      >
        <path
          d='M0 0h998v999H0V0z'
          style={{
            fill: '#000',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <path
          d='M10 988 988 10l11-10H0v998l10-10z'
          style={{
            fill: '#ccc',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <path
          d='M10 10h978v977H10V10z'
          style={{
            fill: '#7f7f7f',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <path
          d='M93 103h635v489H93V103'
          style={{
            fill: 'none',
            stroke: '#fff',
            strokeWidth: 2,
          }}
        />
        <path
          d='m93 592 10-10 615-468 10-11H93v489z'
          style={{
            fill: '#4c4c4c',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <path
          d='M103 114h615v468H103V114z'
          style={{
            fill: '#000',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <path
          d='m270 395 187-188M280 488l260-260M415 457l94-94M218 322l145-146'
          style={{
            fill: 'none',
            stroke: '#fff',
            strokeWidth: 2,
          }}
        />
        <path
          d='M738 145h167v83H738v-83z'
          style={{
            fill: '#e5e5e5',
          }}
        />
        <path
          d='m907 145-2-2H738v4h167l-2-2h4v-2h-2z'
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='m905 231 2-3v-83h-4v83l2-2v5h2v-3z'
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='m736 228 2 3h167v-5H738l3 2h-5v3h2z'
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='m738 143-2 2v83h5v-83l-3 2v-4h-2v2z'
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='M738 228h167v84H738v-84z'
          style={{
            fill: '#e5e5e5',
          }}
        />
        <path
          d='m907 228-2-2H738v5h167l-2-3h4v-2h-2z'
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='m905 314 2-2v-84h-4v84l2-3v5h2v-2z'
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='m736 312 2 2h167v-5H738l3 3h-5v2h2z'
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='m738 226-2 2v84h5v-84l-3 3v-5h-2v2z'
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='M738 312h167v83H738v-83z'
          style={{
            fill: '#e5e5e5',
          }}
        />
        <path
          d='m907 312-2-3H738v5h167l-2-2h4v-3h-2z'
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='m905 397 2-2v-83h-4v83l2-3v5h2v-2z'
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='m736 395 2 2h167v-5H738l3 3h-5v2h2z'
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='m738 309-2 3v83h5v-83l-3 2v-5h-2v3z'
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='M738 395h167v83H738v-83z'
          style={{
            fill: '#e5e5e5',
          }}
        />
        <path
          d='m907 395-2-3H738v5h167l-2-2h4v-3h-2z'
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='m905 480 2-2v-83h-4v83l2-2v4h2v-2z'
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='m736 478 2 2h167v-4H738l3 2h-5v2h2z'
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='m738 392-2 3v83h5v-83l-3 2v-5h-2v3z'
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='M738 478h167v83H738v-83z'
          style={{
            fill: '#e5e5e5',
          }}
        />
        <path
          d='m907 478-2-2H738v4h167l-2-2h4v-2h-2z'
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='m905 564 2-3v-83h-4v83l2-2v5h2v-3z'
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='m736 561 2 3h167v-5H738l3 2h-5v3h2z'
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='m738 476-2 2v83h5v-83l-3 2v-4h-2v2z'
          style={{
            fill: '#7f7f7f',
          }}
        />
        <circle
          cx={863}
          cy={186}
          r={31}
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='M897 186v-3l-1-3-1-4-1-3-1-3-2-2-2-3-2-2-2-2-3-2-3-2-3-1-3-1-3-1-3-1h-4v5h6l3 1 2 1 3 1 2 2 3 1 2 2 2 2 1 2 2 3 1 2 1 3 1 3v5zM863 220h4l3-1h3l3-2 3-1 3-2 3-2 2-2 2-2 2-3 2-2 1-3 1-4 1-3 1-3v-4h-5v6l-1 3-1 3-1 2-2 3-1 2-2 2-2 2-3 1-2 2-3 1-2 1-3 1h-6zM830 186v4l1 3v3l1 4 2 3 1 2 2 3 3 2 2 2 3 2 2 2 3 1 3 2h3l4 1h3v-5h-6l-2-1-3-1-2-1-3-2-2-1-2-2-2-2-2-2-1-3-1-2-1-3-1-3v-3l-1-3zM863 153h-3l-4 1-3 1-3 1-3 1-2 2-3 2-2 2-3 2-2 3-1 2-2 3-1 3v4l-1 3v3h4l1-3v-2l1-3 1-3 1-2 1-3 2-2 2-2 2-2 2-1 3-2 2-1 3-1 2-1h6z'
          style={{
            fill: '#000',
          }}
        />
        <circle
          cx={863}
          cy={270}
          r={31}
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='M897 270v-4l-1-3-1-3-1-3-1-3-2-3-2-3-2-2-2-2-3-2-3-2-3-1-3-1-3-1-3-1h-4v5h3l3 1h3l2 1 3 1 2 2 3 2 2 1 2 2 1 3 2 2 1 3 1 2 1 3v6zM863 303h7l3-1 3-1 3-2 3-1 3-2 2-2 2-3 2-2 2-3 1-3 1-3 1-3 1-4v-3h-5v6l-1 2-1 3-1 3-2 2-1 2-2 2-2 2-3 2-2 1-3 1-2 2h-3l-3 1h-3zM830 270v3l1 4v3l1 3 2 3 1 3 2 2 3 3 2 2 3 2 2 1 3 2 3 1 3 1h7v-4h-3l-3-1h-2l-3-2-2-1-3-1-2-2-2-2-2-2-2-2-1-2-1-3-1-3-1-2v-3l-1-3zM863 236h-3l-4 1-3 1-3 1-3 1-2 2-3 2-2 2-3 2-2 3-1 3-2 3-1 3v3l-1 3v4h4l1-3v-3l1-3 1-2 1-3 1-2 2-3 2-2 2-1 2-2 3-2 2-1 3-1h2l3-1h3z'
          style={{
            fill: '#000',
          }}
        />
        <circle
          cx={863}
          cy={353}
          r={31}
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='M897 353v-3l-1-4-1-3-1-3-1-3-2-3-2-2-2-3-2-2-3-2-3-1-3-2-3-1-3-1h-7v4h3l3 1h3l2 2 3 1 2 1 3 2 2 2 2 2 1 2 2 2 1 3 1 2 1 3v6zM863 387l4-1h3l3-1 3-1 3-2 3-1 3-2 2-2 2-3 2-2 2-3 1-3 1-3 1-3 1-4v-3h-5v6l-1 3-1 2-1 3-2 2-1 2-2 2-2 2-3 2-2 1-3 2-2 1h-3l-3 1h-3zM830 353v3l1 4v3l1 3 2 3 1 3 2 2 3 3 2 2 3 2 2 1 3 2 3 1 3 1h4l3 1v-5h-3l-3-1h-2l-3-1-2-2-3-1-2-2-2-2-2-2-2-2-1-2-1-3-1-2-1-3v-3l-1-3zM863 320h-7l-3 1-3 1-3 2-2 1-3 2-2 2-3 3-2 2-1 3-2 3-1 3v3l-1 4v3h4l1-3v-3l1-3 1-2 1-3 1-2 2-2 2-2 2-2 2-2 3-1 2-1 3-2h2l3-1h3z'
          style={{
            fill: '#000',
          }}
        />
        <circle
          cx={863}
          cy={436}
          r={31}
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='M897 436v-3l-1-4-1-3-1-3-1-3-2-2-2-3-2-2-2-3-3-2-3-1-3-2-3-1-3-1h-7v4l3 1h3l3 1 2 1 3 1 2 1 3 2 2 2 2 2 1 2 2 3 1 2 1 3 1 2v6zM863 470h4l3-1 3-1 3-1 3-1 3-2 3-2 2-2 2-2 2-3 2-3 1-3 1-3 1-3 1-3v-4h-5v6l-1 3-1 2-1 3-2 2-1 3-2 2-2 2-3 1-2 2-3 1-2 1-3 1h-6zM830 436v4l1 3v3l1 3 2 3 1 3 2 3 3 2 2 2 3 2 2 2 3 1 3 1 3 1 4 1h3v-5h-6l-2-1-3-1-2-1-3-2-2-1-2-2-2-2-2-3-1-2-1-3-1-2-1-3v-3l-1-3zM863 403h-7l-3 1-3 1-3 2-2 1-3 2-2 3-3 2-2 3-1 2-2 3-1 3v3l-1 4v3h4l1-3v-3l1-2 1-3 1-2 1-3 2-2 2-2 2-2 2-2 3-1 2-1 3-1 2-1h3l3-1z'
          style={{
            fill: '#000',
          }}
        />
        <circle
          cx={863}
          cy={519}
          r={31}
          style={{
            fill: '#7f7f7f',
          }}
        />
        <path
          d='M897 519v-3l-1-3-1-4-1-3-1-3-2-2-2-3-2-2-2-2-3-2-3-2-3-1-3-1-3-1-3-1h-4v5h6l3 1 2 1 3 1 2 2 3 1 2 2 2 2 1 2 2 3 1 2 1 3 1 3v5zM863 553h4l3-1h3l3-2 3-1 3-2 3-2 2-2 2-2 2-3 2-2 1-3 1-4 1-3 1-3v-4h-5v6l-1 3-1 3-1 2-2 3-1 2-2 2-2 2-3 1-2 2-3 1-2 1-3 1h-6zM830 519v4l1 3v3l1 4 2 3 1 2 2 3 3 2 2 2 3 2 2 2 3 1 3 2h3l4 1h3v-5h-6l-2-1-3-1-2-1-3-2-2-1-2-2-2-2-2-2-1-3-1-2-1-3-1-3v-3l-1-3zM863 486h-3l-4 1-3 1-3 1-3 1-2 2-3 2-2 2-3 2-2 3-1 2-2 3-1 3v4l-1 3v3h4l1-3v-2l1-3 1-3 1-2 1-3 2-2 2-2 2-2 2-1 3-2 2-1 3-1 2-1h6z'
          style={{
            fill: '#000',
          }}
        />
        <path
          d='M93 655h812v260H93V655z'
          style={{
            fill: '#b2b2b2',
            stroke: '#fff',
            strokeWidth: 2,
          }}
        />
        <path
          d='m93 915 10-10 791-240 11-10H93v260z'
          style={{
            fill: '#4c4c4c',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <path
          d='M103 665h792v240H103V665z'
          style={{
            fill: '#7f7f7f',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <path
          d='M114 676h100v72H114v-72zM214 676h100v72H214v-72zM314 676h100v72H314v-72zM414 676h100v72H414v-72zM514 676h100v72H514v-72zM114 749h100v72H114v-72zM214 749h100v72H214v-72zM314 749h100v72H314v-72zM414 749h100v72H414v-72zM514 749h100v72H514v-72zM114 821h100v73H114v-73zM214 821h100v73H214v-73zM314 821h100v73H314v-73zM414 821h100v73H414v-73zM514 821h100v73H514v-73z'
          style={{
            fill: '#b2b2b2',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <path
          d='M634 676h94v218h-94V676z'
          style={{
            fill: '#006',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <path
          d='M749 676h45v54h-45v-54zM749 730h45v55h-45v-55zM749 785h45v54h-45v-54zM749 840h45v54h-45v-54zM794 676h45v54h-45v-54zM794 730h45v55h-45v-55zM794 785h45v54h-45v-54zM794 840h45v54h-45v-54zM839 676h45v54h-45v-54zM839 730h45v55h-45v-55zM839 785h45v54h-45v-54zM839 840h45v54h-45v-54z'
          style={{
            fill: '#e5e5e5',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <ellipse
          cx={771}
          cy={703}
          rx={16}
          ry={20}
          style={{
            fill: '#7f7f7f',
            stroke: '#fff',
            strokeWidth: 2,
          }}
        />
        <ellipse
          cx={771}
          cy={757}
          rx={16}
          ry={20}
          style={{
            fill: '#7f7f7f',
            stroke: '#fff',
            strokeWidth: 2,
          }}
        />
        <ellipse
          cx={771}
          cy={812}
          rx={16}
          ry={20}
          style={{
            fill: '#7f7f7f',
            stroke: '#fff',
            strokeWidth: 2,
          }}
        />
        <ellipse
          cx={771}
          cy={867}
          rx={16}
          ry={20}
          style={{
            fill: '#7f7f7f',
            stroke: '#fff',
            strokeWidth: 2,
          }}
        />
        <ellipse
          cx={816}
          cy={703}
          rx={16}
          ry={20}
          style={{
            fill: '#7f7f7f',
            stroke: '#fff',
            strokeWidth: 2,
          }}
        />
        <ellipse
          cx={816}
          cy={757}
          rx={16}
          ry={20}
          style={{
            fill: '#7f7f7f',
            stroke: '#fff',
            strokeWidth: 2,
          }}
        />
        <ellipse
          cx={816}
          cy={812}
          rx={16}
          ry={20}
          style={{
            fill: '#7f7f7f',
            stroke: '#fff',
            strokeWidth: 2,
          }}
        />
        <ellipse
          cx={816}
          cy={867}
          rx={16}
          ry={20}
          style={{
            fill: '#7f7f7f',
            stroke: '#fff',
            strokeWidth: 2,
          }}
        />
        <ellipse
          cx={862}
          cy={703}
          rx={17}
          ry={20}
          style={{
            fill: '#7f7f7f',
            stroke: '#fff',
            strokeWidth: 2,
          }}
        />
        <ellipse
          cx={862}
          cy={757}
          rx={17}
          ry={20}
          style={{
            fill: '#7f7f7f',
            stroke: '#fff',
            strokeWidth: 2,
          }}
        />
        <ellipse
          cx={862}
          cy={812}
          rx={17}
          ry={20}
          style={{
            fill: '#7f7f7f',
            stroke: '#fff',
            strokeWidth: 2,
          }}
        />
        <ellipse
          cx={862}
          cy={867}
          rx={17}
          ry={20}
          style={{
            fill: '#7f7f7f',
            stroke: '#fff',
            strokeWidth: 2,
          }}
        />
      </svg>
    </NodeWrapper>
  );
});

export default Panel;

Panel.propTypes = {
  id: PropTypes.any,
  data: PropTypes.object,
};

Panel.displayName = 'Panel';
