import React, { memo } from 'react';
import PropTypes from 'prop-types';
import NodeWrapper from '../../wrapper/NodeWrapper';
import { MapContainer, TileLayer } from 'react-leaflet';
import '../../../../assets/css/map.css';

const Map = memo((props) => {
  const { id, data } = props;
  const position = [51.505, -0.09]; // [latitude, longitude]
  const zoomLevel = 13;

  console.log(`${id} node rendered`);

  return (
    <NodeWrapper {...props}>
      <MapContainer
        center={position}
        zoom={zoomLevel}
        scrollWheelZoom={false}
        style={{
          width: data?.common?.size?.width ?? 80,
          height: data?.common?.size?.height ?? 80,
        }}
      >
        <TileLayer
          attribution=''
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          style={{
          width: data?.common?.size?.width ?? 80,
          height: data?.common?.size?.height ?? 80,
        }}
        />
      </MapContainer>
    </NodeWrapper>
  );
});

export default Map;

Map.propTypes = {
  id: PropTypes.any,
  data: PropTypes.object,
};

Map.displayName = 'Map';
