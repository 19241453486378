import React from "react";
import { useRoutes } from "react-router-dom";
import SimpleLayout from "../layouts/SimpleLayout";
import { Flowground } from "../pages/flowground";
import { Preview } from "../pages/preview";
import { BasicMap } from "../pages/map";

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <SimpleLayout />,
      children: [
        {
          path: '',
          element: <Flowground />
        },
        {
          path: 'preview',
          element: <Preview />
        },
        {
          path: 'map',
          element: <BasicMap />
        }
      ]
    }
  ]);
};
