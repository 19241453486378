import React, { memo } from "react";
import PropTypes from "prop-types";
import NodeWrapper from "../../wrapper/NodeWrapper";

const Triangle = memo((props) => {
  const { id, data } = props;

  console.log(`${id} node rendered`);

  return (
    <NodeWrapper {...props} >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={data?.common?.size?.width ?? 80}
          height={data?.common?.size?.height ?? 80}
          fill="red"
          version="1.1"
          viewBox="0 0 511.488 511.488"
          xmlSpace="preserve"
        >
          <path d="M500.04 496.235L265.373 5.568c-3.541-7.424-15.701-7.424-19.243 0L11.464 496.235c-1.6 3.285-1.365 7.189.597 10.283s5.355 4.971 9.024 4.971h469.333c3.669 0 7.083-1.877 9.024-4.971a10.688 10.688 0 00.598-10.283zm-462.037-6.08L255.752 34.88l217.749 455.275H38.003z"></path>
        </svg>
      </div>
    </NodeWrapper>
  )
});

export default Triangle;

Triangle.propTypes = {
  id: PropTypes.any,
  data: PropTypes.object
};

Triangle.displayName = 'Triangle';
