import React, { useMemo } from "react";
import { Box, Collapse, Divider, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { FolderOpen } from '@mui/icons-material';
import useFlowground from '../../../utility/hooks/useFlowground';
import flowgroundSectionSizes from '../../../config/flowgroundSectionSizes';
import { FramePropertiesUpdateModal } from '../modals';
import NodeListItem from '../shared/layerSidebar/NodeListItem';
import applySortByValue from '../../../helpers/flowground/applySortByValue';

const LayerSidebar = () => {
  const {
    layerSidebarWidth,
    nodesLayer,
    toggleNodeLock,
    toggleNodeVisible,
    handleNodeSelect,
    flowgroundHeight,
    setToggleFrameUpdateModal,
    toggleFrameUpdateModal,
    layerListRef
  } = useFlowground();

  const handleClickFrame = () => {
    setToggleFrameUpdateModal((stat) => !stat);
  };

  const LayerItemList = useMemo(() => {

    nodesLayer.sort((a, b) => b.zIndex - a.zIndex);

    return (
      <List
        component="div"
        disablePadding
        sx={{ width: layerSidebarWidth, maxWidth: 360, backgroundColor: '#f1f3f4' }}
      >
        {
          nodesLayer.map((nodeLayer, nodeLayerIndex) => {
            return (
              <NodeListItem
                key={`nodeLayer_${nodeLayerIndex}`}
                layerItem={nodeLayer}
                handleNodeSelect={handleNodeSelect}
                onChangeNodeVisible={toggleNodeVisible}
                onChangeNodeLock={toggleNodeLock}
              />
            )
          })
        }

      </List>
    )

  }, [nodesLayer]);

  return (
    <>
      <Box
        sx={{
          padding: '0!important',
          display: 'flex',
          alignContent: 'flex-start',
          flexWrap: 'wrap',
        }}
      >
        <Box
          sx={{
            padding: 1,
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: '0.875rem',
            fontWeight: 500,
            width: layerSidebarWidth,
            height: flowgroundSectionSizes?.sidebarHeaderHeight,
            boxSizing: 'border-box'
          }}
        >
          Layers
        </Box>
        <Divider
          sx={{
            width: layerSidebarWidth
          }}
        />
        <Box
          ref={layerListRef}
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            height: flowgroundHeight - flowgroundSectionSizes?.sidebarHeaderHeight - 2,
            width: layerSidebarWidth
          }}
        >
          <List
            sx={{ width: layerSidebarWidth, maxWidth: 360, backgroundColor: '#f1f3f4' }}
            component="nav"
          >
            <ListItemButton
              sx={{ py: 0, pl: 1, minHeight: 23 }}
              onClick={handleClickFrame}
            >
              <ListItemIcon
                sx={{
                  minWidth: 36
                }}
              >
                <FolderOpen/>
              </ListItemIcon>
              <ListItemText primary="Frame"/>
            </ListItemButton>
            <Collapse in timeout="auto" unmountOnExit>
              {LayerItemList}
            </Collapse>
          </List>
        </Box>
      </Box>
      {
       toggleFrameUpdateModal && (
         <FramePropertiesUpdateModal/>
       )
      }
    </>
  )
};

export default LayerSidebar;

LayerSidebar.displayName = 'LayerSidebar';
