const menu = [
  {
    label: 'Flowground',
    path: '/'
  },
  {
    label: 'Preview',
    path: '/preview'
  }
];

export default menu;