import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, TextField } from '@mui/material';
import { Formik } from 'formik';
import { makeStyles } from '@mui/styles';
import { Save } from '@mui/icons-material';

// const useStyles = makeStyles(({
//   textField: {
//     '& .MuiInput-formControl': {
//       padding: '5px!important',
//       height: '36px'
//     },
//     '& .MuiFormLabel-root': {
//       lineHeight: '2.1375em'
//     },
//     '& .MuiInputBase-root': {
//       height: '30px',
//       padding: '10px!important'
//     }
//   }
// }));

const SelectedNodeContents = memo((props) => {
  const { selectedNodes, selectedTabIndex, nodeHandleSetting } = props;
  const [initialValues, setInitialValues] = useState({});
  const formRef = useRef(null);

  const onSubmitForm = (values) => {
    nodeHandleSetting(values);
  };

  useEffect(() => {
    if (selectedNodes.length > 0) {
      const selectedNodeValues = selectedNodes[selectedTabIndex];

      setInitialValues({
        id: selectedNodeValues.id,
        name: selectedNodeValues.name,
        width: selectedNodeValues.size.width,
        height: selectedNodeValues.size.height,
        x: selectedNodeValues.position.x,
        y: selectedNodeValues.position.y,
        rotate: selectedNodeValues.rotate,
        opacity: selectedNodeValues.opacity,
      });
    } else {
      setInitialValues({});
    }
  }, [selectedNodes, selectedTabIndex]);

  if (selectedNodes.length > 0) {
    return (
      <>
        <Formik
          initialValues={{
            ...initialValues,
          }}
          onSubmit={onSubmitForm}
          innerRef={formRef}
          enableReinitialize
        >
          {({ values, handleChange, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={0.5}
                  sx={{
                    mt: 1,
                  }}
                >
                  <Grid item md={12}>
                    <Box
                      sx={{
                        paddingX: 1,
                      }}
                    >
                      <TextField
                        margin='dense'
                        label='Name'
                        variant='outlined'
                        name='name'
                        size='small'
                        fullWidth
                        onChange={handleChange}
                        value={values.name || ''}
                      />
                    </Box>
                  </Grid>

                  <Grid item md={12}>
                    <Box
                      sx={{
                        paddingX: 1,
                      }}
                    >
                      <TextField
                        margin='dense'
                        label='Width'
                        variant='outlined'
                        name='width'
                        size='small'
                        fullWidth
                        onChange={handleChange}
                        value={values.width || ''}
                      />
                    </Box>
                  </Grid>

                  <Grid item md={12}>
                    <Box
                      sx={{
                        paddingX: 1,
                      }}
                    >
                      <TextField
                        margin='dense'
                        label='Height'
                        variant='outlined'
                        name='height'
                        size='small'
                        fullWidth
                        onChange={handleChange}
                        value={values.height || ''}
                      />
                    </Box>
                  </Grid>

                  <Grid item md={12}>
                    <Box
                      sx={{
                        paddingX: 1,
                      }}
                    >
                      <TextField
                        margin='dense'
                        label='X-Pos'
                        variant='outlined'
                        name='x'
                        size='small'
                        fullWidth
                        onChange={handleChange}
                        value={values.x || ''}
                      />
                    </Box>
                  </Grid>

                  <Grid item md={12}>
                    <Box
                      sx={{
                        paddingX: 1,
                      }}
                    >
                      <TextField
                        margin='dense'
                        label='Y-Pos'
                        variant='outlined'
                        name='y'
                        size='small'
                        fullWidth
                        onChange={handleChange}
                        value={values.y || ''}
                      />
                    </Box>
                  </Grid>

                  <Grid item md={12}>
                    <Box
                      sx={{
                        paddingX: 1,
                      }}
                    >
                      <TextField
                        type='number'
                        margin='dense'
                        label='Rotate'
                        variant='outlined'
                        name='rotate'
                        size='small'
                        fullWidth
                        onChange={handleChange}
                        value={values.rotate || '0'}
                      />
                    </Box>
                  </Grid>

                  <Grid item md={12}>
                    <Box
                      sx={{
                        paddingX: 1,
                      }}
                    >
                      <TextField
                        type='number'
                        margin='dense'
                        label='Opacity'
                        variant='outlined'
                        name='opacity'
                        size='small'
                        fullWidth
                        onChange={handleChange}
                        value={values.opacity || '0'}
                      />
                    </Box>
                  </Grid>

                  <Grid item md={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        paddingX: 1,
                      }}
                    >
                      <Button type='button' variant='contained' size='small' onClick={handleSubmit}>
                        Save
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </>
    );
  } else {
    return (
      <Box
        sx={{
          textAlign: 'center',
          marginY: 1,
        }}
      >
        No selected node
      </Box>
    );
  }
});

export default SelectedNodeContents;

SelectedNodeContents.propTypes = {
  selectedNodes: PropTypes.array,
  selectedTabIndex: PropTypes.number,
  nodeHandleSetting: PropTypes.func,
};

SelectedNodeContents.displayName = 'SelectedNodeContents';
