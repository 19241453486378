import React, { memo } from 'react';
import PropTypes from 'prop-types';
import NodeWrapper from '../../wrapper/NodeWrapper';

const Pump = memo((props) => {
  const { id, data } = props;

  console.log(`${id} node rendered`);

  return (
    <NodeWrapper {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlSpace='preserve'
        width={data?.common?.size?.width ?? 80}
        height={data?.common?.size?.height ?? 80}
        viewBox='0 0 125.921 121.105'
        {...props}
      >
        <linearGradient
          id='a'
          x1={119.371}
          x2={119.371}
          y1={-412.023}
          y2={-361.615}
          gradientTransform='matrix(1 0 0 -1 0 -360.895)'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0}
            style={{
              stopColor: '#004c00',
            }}
          />
          <stop
            offset={0.21}
            style={{
              stopColor: '#007000',
            }}
          />
          <stop
            offset={0.54}
            style={{
              stopColor: '#4ba54b',
            }}
          />
          <stop
            offset={0.64}
            style={{
              stopColor: '#3d9e3d',
            }}
          />
          <stop
            offset={0.79}
            style={{
              stopColor: '#178b17',
            }}
          />
          <stop
            offset={0.97}
            style={{
              stopColor: '#006c00',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#006500',
            }}
          />
        </linearGradient>
        <path
          fill='url(#a)'
          stroke='#B3B3B3'
          strokeWidth={0.5}
          d='M112.821.719h13.101v50.408h-13.101z'
        />
        <linearGradient
          id='b'
          x1={83.071}
          x2={83.071}
          y1={76.92}
          y2={115.921}
          gradientTransform='matrix(1 0 0 -1 0 122.105)'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0}
            style={{
              stopColor: '#004c00',
            }}
          />
          <stop
            offset={0.52}
            style={{
              stopColor: '#63b163',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#006500',
            }}
          />
        </linearGradient>
        <path fill='url(#b)' d='M53.235 6.184h59.672v39.001h-10.39' />
        <linearGradient
          id='c'
          x1={52.528}
          x2={52.528}
          y1={1094.133}
          y2={1058.253}
          gradientTransform='translate(0 -973.75)'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0}
            style={{
              stopColor: '#319831',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#006500',
            }}
          />
        </linearGradient>
        <path
          fill='url(#c)'
          stroke='#999'
          strokeWidth={0.396}
          d='m11.321 120.384 10.254-35.881h61.908l10.252 35.881H72.966L52.462 89.497l-20.769 30.887H11.321z'
        />
        <g fill='#319831' stroke='#B3B3B3' strokeWidth={0.5}>
          <path d='M.76 115.851h37.533v5.254H.76v-5.254zM66.603 115.851h37.929v5.254H66.603v-5.254z' />
        </g>
        <radialGradient
          id='d'
          cx={126.48}
          cy={837.152}
          r={51.566}
          gradientTransform='translate(-67.998 -780.222) scale(.9999)'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0.18}
            style={{
              stopColor: '#003200',
            }}
          />
          <stop
            offset={0.26}
            style={{
              stopColor: '#003700',
            }}
          />
          <stop
            offset={0.35}
            style={{
              stopColor: '#040',
            }}
          />
          <stop
            offset={0.44}
            style={{
              stopColor: '#005b00',
            }}
          />
          <stop
            offset={0.54}
            style={{
              stopColor: '#007a00',
            }}
          />
          <stop
            offset={0.64}
            style={{
              stopColor: '#47a347',
            }}
          />
          <stop
            offset={0.72}
            style={{
              stopColor: '#97cb97',
            }}
          />
          <stop
            offset={0.81}
            style={{
              stopColor: '#61b061',
            }}
          />
          <stop
            offset={0.99}
            style={{
              stopColor: '#006a00',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#006500',
            }}
          />
        </radialGradient>
        <path
          fill='url(#d)'
          d='M53.047 6.158c28.477.196 51.402 23.439 51.207 51.917-.195 28.475-23.438 51.398-51.914 51.204C23.862 109.086.938 85.841 1.133 57.364c.193-28.475 23.44-51.401 51.914-51.206z'
        />
        <radialGradient
          id='e'
          cx={52.447}
          cy={-418.727}
          r={27.244}
          gradientTransform='matrix(1 0 0 -1 0 -360.895)'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0}
            style={{
              stopColor: '#000',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#003200',
            }}
          />
        </radialGradient>
        <path
          fill='url(#e)'
          d='M38.399 81.168C25.508 73.407 21.35 56.67 29.111 43.78c7.762-12.888 24.502-17.045 37.385-9.285 12.891 7.761 17.047 24.498 9.289 37.393-7.764 12.881-24.499 17.04-37.386 9.28z'
        />
        <linearGradient
          id='f'
          x1={101.982}
          x2={106.141}
          y1={125.54}
          y2={125.534}
          gradientTransform='scale(1 -1) rotate(-8.534 -1150.17 383.538)'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0.01}
            style={{
              stopColor: '#006500',
            }}
          />
          <stop
            offset={0.51}
            style={{
              stopColor: '#6fb76f',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#006500',
            }}
          />
        </linearGradient>
        <path
          fill='url(#f)'
          d='M51.014 77.055c-.642 4.14-2.024 7.351-3.166 7.187-1.139-.165-1.545-3.651-.902-7.789l.014-.081 5.874-37.983c.64-4.139 1.986-7.314 3.125-7.149 1.142.163 1.507 3.623.866 7.76l-5.811 38.055z'
        />
        <linearGradient
          id='g'
          x1={-277.965}
          x2={-273.807}
          y1={21.062}
          y2={21.056}
          gradientTransform='scale(1 -1) rotate(50.824 -28.333 326.992)'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0.01}
            style={{
              stopColor: '#006500',
            }}
          />
          <stop
            offset={0.51}
            style={{
              stopColor: '#6fb76f',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#006500',
            }}
          />
        </linearGradient>
        <path
          fill='url(#g)'
          d='M68.625 68.765c3.234 2.659 5.291 5.485 4.57 6.387-.724.895-3.931-.533-7.162-3.193l-.063-.053-29.686-24.413c-3.234-2.66-5.28-5.437-4.559-6.333.722-.898 3.884.55 7.117 3.21l29.783 24.395z'
        />
        <linearGradient
          id='h'
          x1={216.765}
          x2={220.923}
          y1={433.118}
          y2={433.112}
          gradientTransform='scale(1 -1) rotate(-65.008 -249.428 318.646)'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0.01}
            style={{
              stopColor: '#006500',
            }}
          />
          <stop
            offset={0.51}
            style={{
              stopColor: '#6fb76f',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#006500',
            }}
          />
        </linearGradient>
        <path
          fill='url(#h)'
          d='M35.58 67.368c-3.803 1.752-7.245 2.373-7.739 1.332-.491-1.041 2.193-3.306 5.994-5.057l.076-.033L68.82 47.527c3.805-1.752 7.195-2.384 7.686-1.345.494 1.041-2.188 3.258-5.99 5.008L35.58 67.368z'
        />
        <radialGradient
          id='i'
          cx={51.977}
          cy={64.425}
          r={6.541}
          gradientTransform='matrix(1 0 0 -1 0 122.105)'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset={0}
            style={{
              stopColor: '#6fb76f',
            }}
          />
          <stop
            offset={0.47}
            style={{
              stopColor: '#6bb56b',
            }}
          />
          <stop
            offset={0.64}
            style={{
              stopColor: '#5dae5d',
            }}
          />
          <stop
            offset={0.76}
            style={{
              stopColor: '#45a245',
            }}
          />
          <stop
            offset={0.85}
            style={{
              stopColor: '#239123',
            }}
          />
          <stop
            offset={0.94}
            style={{
              stopColor: '#007c00',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#006500',
            }}
          />
        </radialGradient>
        <path
          fill='url(#i)'
          d='M53.274 51.269a6.536 6.536 0 0 1 5.11 7.709 6.538 6.538 0 1 1-12.815-2.598 6.541 6.541 0 0 1 7.705-5.111z'
        />
      </svg>
    </NodeWrapper>
  );
});

export default Pump;

Pump.propTypes = {
  id: PropTypes.any,
  data: PropTypes.object,
};

Pump.displayName = 'Pump';
