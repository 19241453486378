import React, { useState } from "react";
import PropTypes from "prop-types";
import useFlowground from "../../../utility/hooks/useFlowground";
import ReactFlow, { Background, Controls, MiniMap, Panel, SelectionMode } from 'reactflow';
import { Stack } from "@mui/material";
import 'reactflow/dist/style.css';
import { Add, Save } from '@mui/icons-material';
import { NodeAddModal } from '../modals';
import nodeColor from '../../../config/nodeColor';
import ContextMenu from '../shared/playground/ContextMenu';

const Playground = () => {
  const {
    nodes,
    nodeTypes,
    onNodesChange,
    setNodeIsSaved,
    onSelectionChange,
    nodeIsSaved,
    handleNodeSave,
    toggleNodeAddModal,
    setToggleNodeAddModal,
    handleDeleteNode,
    handleNodeDragStop,
    flowgroundSettings,
    handleOpenContextMenu,
    isOpenContextMenu
  } = useFlowground();


  const toggleNodeAddModalFunc = () => {
    setToggleNodeAddModal((stat) => !stat);
  };

  // context menu
  // const [ isOpenContextMenu, setIsOpenContextMenu ] = useState(false);
  // const [ contextMenuPosition, setContextMenuPosition ] = useState({ x: 0, y: 0 });
  // const [ contextMenuNode, setContextMenuNode ] = useState({});

  // const handleCloseContextMenu = () => {
  //   setIsOpenContextMenu(false);
  // }

  // const handleOpenContextMenu = (event, node) => {
  //   setContextMenuPosition({
  //     x: event.clientX,
  //     y: event.clientY
  //   });
  //   setIsOpenContextMenu(true);
  //   setContextMenuNode(node);
  // }

  return (
    <>
      <ReactFlow
        nodes={nodes}
        onNodesChange={onNodesChange}
        nodeTypes={nodeTypes}
        proOptions={{
          hideAttribution: true,
        }}
        fitView
        maxZoom={50}
        nodesDraggable={true}
        nodesFocusable={true}
        panOnDrag={[ 1, 2 ]}
        selectionOnDrag
        selectionMode={SelectionMode.Full}
        snapToGrid={true}
        snapGrid={[ 1, 1 ]}
        panOnScroll={true}
        zoomActivationKeyCode={'Shift'}
        // panActivationKeyCode={'Meta'}
        onNodeDragStop={(event, node) => {
          handleNodeDragStop(event, node)
        }}
        onSelectionDragStop={(event, nodes) => {
          let isDragged = false;
          nodes.forEach((node) => {
            if (node.dragging) {
              isDragged = true;
            }
          });

          if (isDragged) {
            setNodeIsSaved(true);
          }
        }}
        onSelectionChange={onSelectionChange}
        multiSelectionKeyCode={'Shift'}
        onNodeContextMenu={(event, node) => {
          event.preventDefault();
          handleOpenContextMenu(event, node);
        }}
        onSelectionContextMenu={(event, nodes) => {
          event.preventDefault();
          handleOpenContextMenu(event);
        }}
        onNodesDelete={(nodes) => {
          handleDeleteNode(nodes);
        }}
      >
        <Controls
          showZoom={flowgroundSettings.zoom}
          showFitView={flowgroundSettings.fitOnView}
          showInteractive={false}
        />
        <Background
          variant={flowgroundSettings.backgroundVariant || 'lines'}
          gap={10}
          // size={1}
        />
        {
          flowgroundSettings.minimap === true && (
            <MiniMap
              zoomable={true}
              nodeBorderRadius={5}
              nodeStrokeColor={nodeColor}
              nodeStrokeWidth={8}
              zoomStep={1}
              // onNodeClick={(event, node) => {
              //   console.log({
              //     event,
              //     node
              //   })
              // }}
            />
          )
        }
        <Panel position='top-right'>
          <Stack
            direction='row'
            spacing={1}
          >
            <button
              style={{
                backgroundColor: '#fefefe',
                border: '1px solid #bfbfbf',
                boxSizing: 'content-box',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 24,
                height: 24,
                cursor: 'pointer',
                userSelect: 'none',
                padding: 3.5
              }}
              onClick={toggleNodeAddModalFunc}
            >
              <Add/>
            </button>
            <button
              style={{
                backgroundColor: '#fefefe',
                border: '1px solid #bfbfbf',
                boxSizing: 'content-box',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 24,
                height: 24,
                cursor: 'pointer',
                userSelect: 'none',
                padding: 3.5
              }}
              onClick={handleNodeSave}
              disabled={!nodeIsSaved}
            >
              <Save/>
            </button>
          </Stack>
        </Panel>
      </ReactFlow>
      {
        toggleNodeAddModal && (
          <NodeAddModal/>
        )
      }
      {
        isOpenContextMenu && (
          <ContextMenu />
        )
      }
    </>
  );
};

export default Playground;

Playground.propTypes = {
  grid: PropTypes.number
}
