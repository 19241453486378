import React, { memo } from 'react';
import PropTypes from 'prop-types';
import NodeWrapper from '../../wrapper/NodeWrapper';

const Machine = memo((props) => {
  const { id, data } = props;

  console.log(`${id} node rendered`);

  return (
    <NodeWrapper {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={data?.common?.size?.width ?? 80}
        height={data?.common?.size?.height ?? 80}
        viewBox='0 0 873 999'
        {...props}
      >
        <path
          d='M88 505h697v234H88V505z'
          style={{
            fill: '#666',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <path
          d='m30 962 74-109h665l72 109H30z'
          style={{
            fill: '#999',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <path
          d='M20 962h833v15H20v-15z'
          style={{
            fill: '#b2b2b2',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <path
          d='M104 989V0h665v989H104z'
          style={{
            fill: '#7f7f7f',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <path
          d='M165 165h542v584H165V165z'
          style={{
            fill: '#666',
          }}
        />
        <path
          d='M165 707h542v42H165v-42zM165 165h542v42H165v-42z'
          style={{
            fill: '#333',
          }}
        />
        <path
          d='M165 165h542v584H165V165'
          style={{
            fill: 'none',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <path
          d='M165 781h542v208H165V781z'
          style={{
            fill: '#666',
          }}
        />
        <path
          d='m165 929 542-88v148H165v-60z'
          style={{
            fill: '#616161',
          }}
        />
        <path
          d='m165 937 542-88v140H165v-52z'
          style={{
            fill: '#5c5c5c',
          }}
        />
        <path
          d='m165 949 542-87v127H165v-40z'
          style={{
            fill: '#575757',
          }}
        />
        <path
          d='M165 781h542v20H165v-20z'
          style={{
            fill: '#4c4c4c',
          }}
        />
        <path
          d='M207 145V41h104M561 145V41h104'
          style={{
            fill: 'none',
            stroke: '#b2b2b2',
            strokeWidth: 2,
          }}
        />
        <path
          d='M165 541h542v42H165v-42z'
          style={{
            fill: '#e54c00',
          }}
        />
        <path
          d='M188 519h497l22 22H165l23-22z'
          style={{
            fill: '#f60',
          }}
        />
        <path
          d='M185 281h84V165h62v116h210l-2-116h64v116h84v218H185V281z'
          style={{
            fill: '#c30',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <path
          d='M561 145h104V41M207 145h104V41'
          style={{
            fill: 'none',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <circle
          cx={258}
          cy={92}
          r={31}
          style={{
            fill: 'none',
            stroke: '#666',
            strokeWidth: 2,
          }}
        />
        <circle
          cx={612}
          cy={92}
          r={31}
          style={{
            fill: 'none',
            stroke: '#666',
            strokeWidth: 2,
          }}
        />
        <path
          d='M211 136h6v4h-6v-4zM211 45h6v7h-6v-7zM299 45h6v7h-6v-7zM299 136h6v4h-6v-4zM565 136h6v4h-6v-4zM565 45h6v7h-6v-7zM655 45h4v7h-4v-7zM655 136h4v4h-4v-4z'
          style={{
            fill: '#333',
          }}
        />
        <path
          d='M207 821h56v32h-56v-32z'
          style={{
            fill: '#999',
          }}
        />
        <path
          d='M207 827h56v6h-56v-6z'
          style={{
            fill: '#cc590d',
          }}
        />
        <path
          d='M565 447h12v52h-12v-52zM431 447h10v52h-10v-52zM295 447h10v52h-10v-52zM669 781h16v10h-16v-10zM519 781h16v10h-16v-10zM337 781h16v10h-16v-10zM185 781h16v10h-16v-10zM40 941h9v21h-9v-21zM821 941h10v21h-10v-21z'
          style={{
            fill: '#000',
          }}
        />
        <path
          d='M207 281v-16h332v16H207zM617 281l-4-16 16-48 30-52h26v116h-68z'
          style={{
            fill: '#e54c00',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <path
          d='M165 583h36v104h-36V583zM671 583h36v104h-36V583z'
          style={{
            fill: '#191919',
          }}
        />
        <path
          d='M415 165h42v100h-42V165zM727 124h22v625h-22V124zM124 124h20v625h-20V124z'
          style={{
            fill: '#666',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <path
          d='M421 165h30v100h-30V165zM731 124h14v625h-14V124zM125 124h16v625h-16V124z'
          style={{
            fill: '#b2b2b2',
          }}
        />
        <path
          d='M431 165h10v100h-10V165zM132 124h5v625h-5V124zM735 124h6v625h-6V124z'
          style={{
            fill: '#e5e5e5',
          }}
        />
        <path
          d='M753 519h-26v-20H145v20h-25v42h25v84h20V519h542v42h46v-42z'
          style={{
            fill: '#fc0',
          }}
        />
        <path
          d='M117 687h27v20h583v-20h26v-42h-26v-84h-20v126H165v-42h-48v42z'
          style={{
            fill: '#fc0',
          }}
        />
        <path
          d='M117 645v42h27v20h583v-20h26v-42h-26v-84h26v-42h-26v-20H145v20h-25v42h25v84h-28'
          style={{
            fill: 'none',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <path
          d='M165 519h542v168H165V519M165 583h542'
          style={{
            fill: 'none',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <path
          d='M104 989h665v10H104v-10z'
          style={{
            fill: '#000',
          }}
        />
        <path
          d='M165 781h542v208H165V781'
          style={{
            fill: 'none',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <path
          d='M779 977h94v22h-94v-22zM0 977h92v22H0v-22z'
          style={{
            fill: '#ffb27f',
            stroke: '#4c4c4c',
            strokeWidth: 2,
          }}
        />
        <path
          d='M805 977h42v22h-42v-22zM26 977h39v22H26v-22z'
          style={{
            fill: '#ffd9bf',
          }}
        />
        <path
          d='M117 20h84v104h-84V20zM669 20h84v104h-84V20z'
          style={{
            fill: '#333',
            stroke: '#999',
            strokeWidth: 2,
          }}
        />
      </svg>
    </NodeWrapper>
  );
});

export default Machine;

Machine.propTypes = {
  id: PropTypes.any,
  data: PropTypes.object,
};

Machine.displayName = 'Machine';
