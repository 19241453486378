import React, { memo } from 'react';
import PropTypes from 'prop-types';
import NodeWrapper from '../../wrapper/NodeWrapper';

const Pipe = memo((props) => {
  const { id, data } = props;

  console.log(`${id} node rendered`);

  return (
    <NodeWrapper {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={data?.common?.size?.width ?? 80}
        height={data?.common?.size?.height ?? 80}
        viewBox='0 0 112.5 29.924'
        xmlSpace='preserve'
      >
        <linearGradient
          id='a'
          x1='94.288'
          x2='123.987'
          y1='-23.038'
          y2='-23.038'
          gradientTransform='rotate(-90 101.75 22.462)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.01' stopColor='#006400'></stop>
          <stop offset='0.16' stopColor='#1F8F1F'></stop>
          <stop offset='0.49' stopColor='#BFDFBF'></stop>
          <stop offset='1' stopColor='#006400'></stop>
        </linearGradient>
        <path fill='url(#a)' d='M112.5 29.924H0V.224h112.5v29.7z'></path>
      </svg>
    </NodeWrapper>
  );
});

export default Pipe;

Pipe.propTypes = {
  id: PropTypes.any,
  data: PropTypes.object,
};

Pipe.displayName = 'Pipe';
